<!-- <div
  id="popup"
  class="component-popup"
  [class]="{ hide: !showPopupBool, show: showPopupBool }"
> -->
<!-- <div class="view-proposals"> -->
<div class="content">
  <div class="foreground">
    <h1 class="header thick">View Proposals</h1>
    <!-- <button class="button" (click)="closePopup()">X</button> -->

    <div class="proposals-container">
      <div class="proposal-column">
        <div class="proposal-row"><b>In English</b></div>
        <div class="proposal-row" *ngFor="let proposal of proposals.english">
          <a target="#" class="name" href="{{ proposal.path }}">{{
            proposal.name
          }}</a>
        </div>
      </div>
      <div class="proposal-column">
        <div class="proposal-row"><b>En Español</b></div>
        <div class="proposal-row" *ngFor="let proposal of proposals.spanish">
          <a target="#" class="name" href="{{ proposal.path }}">{{
            proposal.name
          }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->
<!-- <div class="click-out" (click)="closePopup()"></div> -->
<!-- </div> -->
