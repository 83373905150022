<div
  id="page-terms"
  class="mx-auto my-20 w-10/12 lg:mx-auto lg:my-24 lg:w-10/12"
>
  <div
    class="mt-20"
    data-pg-class-style="column"
    data-pg-name="Titles"
    data-pg-class-style-inline=" mt-20"
  >
    <h1
      data-pg-class-style="h1_title"
      class="
        lg:mt-auto lg:mb-2
        text-3xl
        leading-snug
        lg:text-4xl
        text-green-700
      "
      style="font-family: 'Lato', sans-serif; font-weight: 900"
    >
      Terms &amp; Conditions
    </h1>
    <h5
      style="font-family: 'Bitter', serif; font-weight: 700"
      data-pg-class-style="h5_title"
      class="
        lg:text-left lg:text-2xl
        text-xl text-green-500 text-left
        leading-snug
        mb-6
        mx-auto
      "
    >
      DollarZing Inc. - TERMS OF USE
    </h5>
  </div>
  <div
    data-pg-class-style="column"
    class="lg:mt-20 mt-20"
    style="font-family: 'Lato', sans-serif"
    data-pg-name="Acceptance of Terms"
    data-pg-class-style-inline=" lg:mt-20 mt-20"
  >
    <h5
      style="
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        text-decoration: underline;
      "
      data-pg-class-style="h5_title"
      class="
        lg:text-left lg:text-2xl
        text-xl text-green-500 text-left
        leading-snug
        mb-6
        mx-auto
      "
    >
      Acceptance of Terms
    </h5>
    <p
      class="leading-8 text-lg mb-4 mt-4"
      data-pg-class-style="paragraph_terms"
      data-pg-class-style-inline=" mt-4"
    >
      Welcome to DOLLARZING.Com, powered by DollarZing Inc. You should carefully
      review the following terms and conditions. DollarZing requires that all
      donors, charitable organizations (&ldquo;causes&rdquo;) and all other
      visitors (collectively known as &ldquo;users&rdquo;/&rdquo;members&rdquo;)
      to its site (&ldquo;the site&rdquo;) agree to the terms and conditions set
      forth in this User Agreement. BY USING THE SERVICE IN ANY MANNER,
      INCLUDING, BUT NOT LIMITED TO, VISITING OR BROWSING THE SITE OR
      CONTRIBUTING CONTENT, INFORMATION, OR OTHER MATERIALS OR SERVICES TO THE
      SITE, YOU AGREE TO BE BOUND BY THIS AGREEMENT.
    </p>
    <p
      data-pg-class-style="paragraph_highllights"
      class="font-bold mb-2 mt-6 text-lg"
    >
      100% of donations made go to your charity, no deductions, no fees.
    </p>
    <p
      class="font-bold mb-2 mt-6 text-lg"
      data-pg-class-style="paragraph_highllights"
    >
      Pending payments to charities, donations are held in a non-interest
      bearing account.
    </p>
    <p
      class="leading-8 text-lg mb-4 mt-6"
      data-pg-class-style="paragraph_terms"
      data-pg-class-style-inline=" mt-6"
    >
      By signing up on the DollarZing Platform As A Service you agree to Gift a
      maximum of $10.00 per day to the DollarZing Community, Donate a minimum of
      10% of any received daily $ZingCash and pay a per click fee of .10 per
      click during the game play challenge to DollarZing Inc for the use of the
      platform. As you receive from the community and gift to your charitable
      organization you agree to have your profile picture presented to the
      charitable partner for recognition on the DollarZing Community page.
      DollarZing Inc. collects certain information from visitors to its Site,
      but we will never sell your information. For further explanation, see our
      Privacy Policy.&nbsp;
    </p>
  </div>
  <div
    data-pg-name="Summary of Service"
    data-pg-class-style="column"
    class="lg:mt-20 mt-20"
    style="font-family: 'Lato', sans-serif"
    data-pg-class-style-inline=" lg:mt-20 mt-20"
  >
    <h5
      data-pg-class-style="h5_title"
      class="
        lg:text-left lg:text-2xl
        text-xl text-green-500 text-left
        leading-snug
        mb-6
        mx-auto
      "
      data-pg-class-style-inline=""
      style="text-decoration: underline; font-weight: bold"
    >
      Summary of Service
    </h5>
    <ul style="font-family: 'Lato', sans-serif">
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        DOLLARZING INC. is a Gifting and Receiving Platform As A Service that
        allows users to play a Peer to Peer Gifting and Receiving Challenge Game
        and donate their received $ZingCash to their favorite causes, so that
        the nonprofits can create impactful micro-action on the user&rsquo;s
        behalf.&nbsp;
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        DOLLARZING INC. Commits to NEVER taking advertising dollars for
        DollarZing Inc. All charitable partner&rsquo;s cash, products or service
        will be on our free game play where our partners can put cash, products
        or services for donating to the DollarZing community to play for in
        there names in the 24hr period set by the game rules or rules that apply
        to the specific Free Game play challenge.&nbsp;
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        When you receive your $ZingCash, you can choose between various causes
        and impact actions on the charitable partner platform that you want to
        support. A minimum of 10% from received $ZingCash from the community
        will go to your cause picked prior to the DollarZing game challenge.
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        DOLLARZING.COM is free to join. Only registered users can Gift and
        Receive on the DollarZing.com Platform.
      </li>
      <li
        data-pg-class-style="paragraph_points"
        class="leading-7 mt-4 text-lg list-inside list-disc"
      >
        For every impact action by a user, DollarZing Inc. will pay the
        charitable partner the amount indicated by your pre-set percentage of
        10% minimum and 100% maximum donation to the charitable partner you have
        chosen prior to your $ZingCash gift received from the community.
      </li>
      <li
        data-pg-class-style="paragraph_points"
        class="leading-7 mt-4 text-lg list-inside list-disc"
      >
        DollarZing Inc. requires the charitable partners it contracts with to
        provide various types of documentation to prove that the impact actions
        were, in fact, performed; however, you agree that you waive and release
        DollarZing Inc. and its subsidiaries, affiliates, partners, officers,
        directors, employees and agents from any&nbsp;liabilities arising from
        or related to any act of omission or non-performance of an impact action
        by a cause partner in connection with your gift. DollarZing Inc is a
        Transparency based corporation and will be inputting our proprietary
        &ldquo;Follow The Dollar&rdquo; technology as we continue to grow our
        Platform As A Service.
      </li>
      <li
        data-pg-class-style="paragraph_points"
        class="leading-7 mt-4 text-lg list-inside list-disc"
      >
        DollarZing Inc. has a strict onboarding and transparency policy for all
        of our charitable partners on our platform. We make no representations
        or warranties as to the availability and quality of any action or
        service listed or promoted on this web site. Charitable partners and
        DollarZing Inc. reserve the right to add or delete any charitable
        partner for any reason deemed necessary.
      </li>
      <li
        data-pg-class-style="paragraph_points"
        class="leading-7 mt-4 text-lg list-inside list-disc"
      >
        Charitable partners can submit a new profile on
        www.DollarZing.com/Charities. or by contacting admin@DollarZing.com.
        Participation as a charitable partner is subject to DollarZing&rsquo;s
        prior approval. DollarZing Inc. will make payments to its charitable
        partners monthly for charitable partners receiving $250.00 or more and
        no longer than 60 days to any partner that is below $250.00 via ACH,
        check or wire payment.
      </li>
    </ul>
  </div>
  <div
    data-pg-name="General Terms"
    data-pg-class-style="column"
    class="lg:mt-20 mt-20"
    style="font-family: 'Lato', sans-serif"
    data-pg-class-style-inline=" lg:mt-20 mt-20"
  >
    <h5
      data-pg-class-style="h5_title"
      class="
        lg:text-left lg:text-2xl
        text-xl text-green-500 text-left
        leading-snug
        mb-6
        mx-auto
      "
      style="font-weight: bold; text-decoration: underline"
    >
      General Terms
    </h5>
    <h6 style="font-weight: bold; text-decoration: underline">User Content</h6>
    <p
      data-pg-class-style="paragraph_terms"
      class="leading-8 text-lg mb-4 mt-4"
      data-pg-class-style-inline=" mt-4"
    >
      Some areas of the Service may allow Users to post feedback, comments,
      questions, and other information. Any such postings constitute &quot;User
      Content.&quot; You are solely responsible for your User Content that you
      upload, publish, display, link to or otherwise make available
      (hereinafter, &quot;post&quot;) on the Service, and you agree that we are
      only acting as a passive conduit for your online distribution and
      publication of your User Content. You must be the owner of all the
      Intellectual Property Rights (as defined below) in the User Content you
      post, or have explicit permission from the owner(s) of all such rights to
      post the User Content on DollarZing.com.
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      For the purposes of this Agreement, &quot;Intellectual Property
      Rights&quot; means all patent rights, copyright rights, mask work rights,
      moral rights, rights of publicity, trademark, trade dress and service mark
      rights, goodwill, trade secret rights and other intellectual property
      rights as may now exist or hereafter come into existence, and all
      applications therefore and registrations, renewals and extensions thereof,
      under the laws of any state, country, territory or other jurisdiction.
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      You agree not to post or send any information or content that we deem to
      be unlawful, harmful, abusive, racially or ethnically offensive,
      defamatory, infringing, invasive of personal privacy or publicity rights,
      harassing, humiliating to other people (publicly or otherwise), libelous,
      threatening, or otherwise objectionable; contains any information or
      content that is illegal or that you know is not correct and current.
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      You agree that any User Content that you post does not and will not
      violate third-party rights of any kind, including without limitation any
      Intellectual Property Rights, rights of publicity and privacy.
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      DollarZing Inc. reserves the right to reject and/or remove any User
      Content that DollarZing Inc. believes, in its sole discretion, violates
      these provisions.
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      In consideration of your use of the Site or participation in the Program,
      you agree to (a) provide accurate, current and complete information about
      you as may be prompted by any registration forms on the Site
      (&quot;Registration Data&quot;); (b) maintain the security of your
      password and identification; (c) maintain and promptly update the
      Registration Data, and any other information you provide to
      DollarZing.com, to keep it accurate, current and complete; and (d) be
      fully responsible for all use of your account and for any actions that
      take place using your account. You agree not to impersonate any person or
      entity.
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      DollarZing Inc. reserves the right to terminate use of the Website by you
      by disabling your access to the Website immediately without notice and may
      refuse to fulfill your membership, if DollarZing Inc., in their sole
      discretion, determines that 1) your use of the Website does not comply
      with these terms and conditions governing your use of the website, or 2)
      determines that you have violated the terms and conditions of any code, or
      offer of any kind, or 3) determines that you have violated any local,
      state, national or international laws, or 4) determines that you have
      interfered with any other&rsquo;s use of the Website, or in any way
      adversely affected operation of the Website by DollarZing.com.
    </p>
  </div>
  <div
    data-pg-name="License Grant"
    data-pg-class-style="column"
    class="lg:mt-20 mt-20"
    style="font-family: 'Lato', sans-serif"
    data-pg-class-style-inline=" lg:mt-20 mt-20"
  >
    <h5
      data-pg-class-style="h5_title"
      class="
        lg:text-left lg:text-2xl
        text-xl text-green-500 text-left
        leading-snug
        mb-6
        mx-auto
      "
      style="font-weight: bold; text-decoration: underline"
    >
      License Grant
    </h5>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      By posting any User Content on the Service, you expressly grant, and you
      represent and warrant that you have a right to grant, to DollarZing.com a
      royalty-free, sublicensable, transferable, perpetual, irrevocable,
      non-exclusive, worldwide license to use, reproduce, modify, publish, list
      information regarding, edit, translate, distribute, publicly perform,
      publicly display, and make derivative works of all such User Content and
      your name, voice, and/or likeness as contained in your User Content, in
      whole or in part, and in any form, media or technology, whether now known
      or hereafter developed for use in connection with the Service.
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      By signing up on the DollarZing Platform as A Service you agree to pay a
      per click fee of .10 per click during the game play challenge. As you
      receive from the community and gift to your charitable organization you
      agree to have your profile picture presented to the charitable partner for
      recognition on the DollarZing Community page. DollarZing Inc. collects
      certain information from visitors to its Site, but we will never sell your
      information. For further explanation, see our Privacy Policy.
    </p>
  </div>
  <div
    class="lg:mt-20 mt-20"
    data-pg-name="Proprietary Rights"
    data-pg-class-style="column"
    style="font-family: 'Lato', sans-serif"
    data-pg-class-style-inline=" lg:mt-20 mt-20"
  >
    <h5
      style="font-weight: bold; text-decoration: underline"
      data-pg-class-style="h5_title"
      class="
        lg:text-left lg:text-2xl
        text-xl text-green-500 text-left
        leading-snug
        mb-6
        mx-auto
      "
    >
      Proprietary Rights
    </h5>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      DollarZing&rsquo;s and our charitable partners logo, graphics, designs,
      page headers, button icons, scripts, names and branding are trademarks,
      service marks or trade dress and cannot be used without prior written
      permission from DollarZing Inc or our charitable partners.
    </p>
  </div>
  <div
    data-pg-name="Warranties"
    data-pg-class-style="column"
    class="lg:mt-20 mt-20"
    style="font-family: 'Lato', sans-serif"
    data-pg-class-style-inline=" lg:mt-20 mt-20"
  >
    <h5
      data-pg-class-style="h5_title"
      class="
        lg:text-left lg:text-2xl
        text-xl text-green-500 text-left
        leading-snug
        mb-6
        mx-auto
      "
      style="font-weight: bold; text-decoration: underline"
    >
      Warranties
    </h5>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      DollarZing Inc. is not making any warranties and is not responsible or
      liable for the accuracy of content found on the Site or in its programs.
      DollarZing Inc. is not responsible under no circumstances for any loss or
      damage, including, without limitation, personal injury or death, resulting
      from any use of the Site or participation in the Program, including
      without limitation any content posted, uploaded, submitted, transmitted,
      or otherwise shared on the Site or any interactions between any Users of
      the Site, or Program, whether online or offline. DollarZing Inc. does not
      represent, warrant, covenant, guarantee or promise any specific results
      from use of the Site or the Program.
    </p>
  </div>
  <div
    data-pg-name="Limitation of Liability"
    style="font-family: 'Lato', sans-serif"
    data-pg-class-style="column"
    class="lg:mt-20 mt-20"
    data-pg-class-style-inline=" lg:mt-20 mt-20"
  >
    <h5
      style="font-weight: bold; text-decoration: underline"
      data-pg-class-style="h5_title"
      class="
        lg:text-left lg:text-2xl
        text-xl text-green-500 text-left
        leading-snug
        mb-6
        mx-auto
      "
    >
      Limitation of Liability
    </h5>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      You understand that any and all decision made by you with respect to the
      Site and Gifting are yours alone. DollarZing Inc. cannot and does not
      verify the accuracy of information from users and charitable partners.
      DollarZing Inc. shall not be responsible, or have any duty or obligation
      to, or liability for: (a) decisions or interactions resulting (directly or
      indirectly) from participation in Gifting or Receiving; or (b) any
      damages, costs, losses or expenses a User incurs as a result (directly or
      indirectly) of providing a service or as a result (directly or indirectly)
      of utilizing the Platform or information received in connection with the
      Platform. In addition, in no event will DollarZing Inc. be liable to you
      or any third person for any damages, costs, losses or expenses, including
      any lost capital, lost profits or special, incidental, consequential or
      punitive damages arising from your use of the Site or participation in the
      Gifting and Receiving Platform, even if DollarZing Inc. has been advised
      of the possibility of such damages, costs, losses or expenses.
    </p>
  </div>
  <div
    data-pg-name="Governing Law, ..."
    data-pg-class-style="column"
    class="lg:mt-20 mt-20"
    style="font-family: 'Lato', sans-serif"
    data-pg-class-style-inline=" lg:mt-20 mt-20"
  >
    <h5
      data-pg-class-style="h5_title"
      class="
        lg:text-left lg:text-2xl
        text-xl text-green-500 text-left
        leading-snug
        mb-6
        mx-auto
      "
      style="font-weight: bold; text-decoration: underline"
    >
      Governing Law; Resolution of Disputes; Arbitration and Waivers
    </h5>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      This Agreement is governed by the laws of the State of Missouri, USA,
      without regard to its choice of law or conflict of law provisions. If any
      disputes arises between you and DollarZing Inc., including without
      limitation, any dispute arising from or relating to the Site or the
      Platform, you agree that all such disputes will be determined exclusively
      by final and binding arbitration, in accordance with the then existing
      commercial rules of the American Arbitration in St. Louis if the amount
      claimed is greater than $100. The arbitration shall be heard and
      adjudicated by one arbitrator to be selected by you and DollarZing Inc
      council. Any award will be final, binding and conclusive upon the parties,
      subject only to judicial review provided by Missouri statute, and a
      judgment rendered on the arbitration award can be entered in any court
      having jurisdiction thereof. Notwithstanding the foregoing, either you or
      DollarZing Inc may seek any injunctive relief in a state or federal court
      in St. Louis, Missouri, as may be necessary to preserve rights pending the
      completion of arbitration and DollarZing Inc. may seek injunctive relief
      in a state or federal court in St. Louis, Missouri, or another court of
      competent jurisdiction, at any time against any violations of Proprietary
      Right or Acceptance of Terms of this Agreement. TO THE EXTENT PERMITTED BY
      APPLICABLE LAW, BY AGREEING TO SUBMIT ALL DISPUTES TO BINDING ARBITRATION,
      YOU AND DOLLARZING INC. HEREBY WAIVE YOUR RIGHT TO A JURY OR COURT TRIAL
      OF ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR BASED UPON THIS
      AGREEMENT OR ANY CONTEMPLATED USE HEREIN, INCLUDING CONTRACT, TORT, BREACH
      OF DUTY AND ALL OTHER CLAIMS, EXCEPT AS SPECIFICALLY PROVIDED ABOVE. You
      waive, to the maximum extent not prohibited by law, any right you may have
      to claim or recover, in any legal action or proceeding, any special,
      exemplary, punitive or consequential damages.
    </p>
  </div>
  <div
    data-pg-name="Indemnity"
    data-pg-class-style="column"
    class="lg:mt-20 mt-20"
    style="font-family: 'Lato', sans-serif"
    data-pg-class-style-inline=" lg:mt-20 mt-20"
  >
    <h5
      style="font-weight: bold; text-decoration: underline"
      data-pg-class-style="h5_title"
      class="
        lg:text-left lg:text-2xl
        text-xl text-green-500 text-left
        leading-snug
        mb-6
        mx-auto
      "
    >
      Indemnity
    </h5>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      You agree to indemnify and hold DollarZing Inc. harmless from any loss,
      liability, claim, or demand, including reasonable attorney&apos;s fees,
      made or incurred by any third party due to or arising (directly or
      indirectly) out of your use of the Site or participation in the Gifting
      and Receiving Platform AS A Service or arising from your breach of this
      Agreement.
    </p>
  </div>
  <div
    data-pg-name="Modifications to User..."
    data-pg-class-style="column"
    class="lg:mt-20 mt-20"
    style="font-family: 'Lato', sans-serif"
    data-pg-class-style-inline=" lg:mt-20 mt-20"
  >
    <h5
      data-pg-class-style="h5_title"
      class="
        lg:text-left lg:text-2xl
        text-xl text-green-500 text-left
        leading-snug
        mb-6
        mx-auto
      "
      style="font-weight: bold; text-decoration: underline"
    >
      Modifications to the User Agreement
    </h5>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      We reserve the right, at our discretion, to change, modify, add or remove
      portions of this User Agreement at any time. Please check this User
      Agreement and any Guidelines periodically for changes. Your continued use
      of the Site after the posting of changes constitutes your binding
      acceptance of such changes.
    </p>
  </div>
  <div
    data-pg-name="Availability of site"
    data-pg-class-style="column"
    class="lg:mt-20 mt-20"
    style="font-family: 'Lato', sans-serif"
    data-pg-class-style-inline=" lg:mt-20 mt-20"
  >
    <h5
      data-pg-class-style="h5_title"
      class="
        lg:text-left lg:text-2xl
        text-xl text-green-500 text-left
        leading-snug
        mb-6
        mx-auto
      "
      style="text-decoration: underline; font-weight: bold"
    >
      Availability of the Site
    </h5>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      The Site may change or contain media, features or services available
      within the site and may be discontinued at any time, and without notice.
      The media or services on the Site may be out of date, and DollarZing Inc.
      makes no commitment to update these materials immediately on the site.
    </p>
  </div>
  <div
    data-pg-name="Entire Agreement"
    data-pg-class-style="column"
    class="lg:mt-20 mt-20"
    style="font-family: 'Lato', sans-serif"
    data-pg-class-style-inline=" lg:mt-20 mt-20"
  >
    <h5
      data-pg-class-style="h5_title"
      class="
        lg:text-left lg:text-2xl
        text-xl text-green-500 text-left
        leading-snug
        mb-6
        mx-auto
      "
      style="font-weight: bold; text-decoration: underline"
    >
      Entire Agreement, Severability
    </h5>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      This Agreement, accepted upon your access and use of the Site or
      participation in the Gifting and Receiving Platform As A Service and
      further affirmed by becoming a User/Member, contains the entire agreement
      between you and DollarZing Inc. regarding the use of the Site or the
      Platform. This Agreement may not be orally amended. If any provision of
      this Agreement is held invalid, the remainder of this Agreement shall
      continue in full force and effect. Please contact us at
      support@DollarZing.com. with any questions regarding this Agreement
    </p>
  </div>
</div>
