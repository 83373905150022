import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { CharityPageComponent } from './pages/charity-page/charity-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { GamePageComponent } from './pages/game-page/game-page.component';
import { AmbassadorComponent } from './components/ambassador/ambassador.component';
import { NgxSplideModule } from 'ngx-splide';
import { CharityEnrollmentPageComponent } from './pages/charity-enrollment-page/charity-enrollment-page.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { PrivacyPageComponent } from './pages/privacy-page/privacy-page.component';
import { AmbassadorEnrollmentPageComponent } from './pages/ambassador-enrollment-page/ambassador-enrollment-page.component';
import { PlazaLibertadPageComponent } from './pages/plaza-libertad-page/plaza-libertad-page.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PopupComponent } from './components/popup/popup.component';
import { PopupPlazaProposalsComponent } from './components/popup-plaza-proposals/popup-plaza-proposals.component';
import { SignToDonatePageComponent } from './pages/sign-to-donate-page/sign-to-donate-page.component';
import { PuertoRicoPageComponent } from './pages/puerto-rico-page/puerto-rico-page.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    AboutPageComponent,
    CharityPageComponent,
    HomePageComponent,
    GamePageComponent,
    AmbassadorComponent,
    CharityEnrollmentPageComponent,
    TermsPageComponent,
    PrivacyPageComponent,
    AmbassadorEnrollmentPageComponent,
    PlazaLibertadPageComponent,
    PopupComponent,
    PopupPlazaProposalsComponent,
    SignToDonatePageComponent,
    PuertoRicoPageComponent,
  ],
  imports: [BrowserModule, AppRoutingModule, NgxSplideModule, FontAwesomeModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
