<div
  class="
    bg-base-green
    flex flex-col-reverse
    items-center
    pb-16
    pt-16
    w-full
    lg:flex-row lg:items-start lg:pl-24 lg:pr-6 lg:pt-28
    xl:pl-64 xl:pr-20
  "
  id="component-ambassador"
  [class.hide]="hide"
>
  <div class="flex flex-col-reverse mt-8 w-9/12 lg:mt-0 lg:w-1/2">
    <div class="flex flex-col pb-12 pt-8 sm:px-14 lg:mb-12">
      <span
        class="font-lato-no-weight text-xxs mb-2"
        style="font-weight: 900; text-transform: uppercase"
        >Ambassador Program</span
      >
      <h1
        class="
          font-cholla-slab-ot-no-weight
          text-xl
          md:text-4xl
          lg:text-3xl
          xl:text-5xl
          pb-3
          leading-tight
        "
        style="font-weight: bold"
      >
       Become a Zinger & Subscribe to our Newsletter

      </h1>
      <p
        class="font-lato mb-6"
        style="font-size: 14px; line-height: 22px; letter-spacing: 0.05em"
      >
        As a Zinger, you will receive important information about DollarZing and the charities we work with. Join us!

      </p>
      <!-- <p class="mb-2">
        <span
          class="font-lato-no-weight text-xxs"
          style="font-weight: 900; text-transform: uppercase"
          >Sign up here:
        </span>
      </p>
      <input type="email" placeholder="email" class="p-3" /> -->
      <a routerLink="/ambassador-enrollment">
        <button class="button">Sign Up Here</button>
      </a>
    </div>
  </div>
  <div class="w-9/12 lg:mr-10 lg:w-1/2">
    <img src="../../../assets/images/Home Ambassador Room.png" class="w-full" />
  </div>
</div>
