import { Component, OnInit } from '@angular/core';
import { scrollToTop } from 'src/assets/utils/scrollToTop';

@Component({
  selector: 'app-charity-page',
  templateUrl: './charity-page.component.html',
  styleUrls: ['./charity-page.component.scss'],
})
export class CharityPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    scrollToTop();
  }
}
