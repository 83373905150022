import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { AmbassadorEnrollmentPageComponent } from './pages/ambassador-enrollment-page/ambassador-enrollment-page.component';
import { CharityEnrollmentPageComponent } from './pages/charity-enrollment-page/charity-enrollment-page.component';
import { CharityPageComponent } from './pages/charity-page/charity-page.component';
import { GamePageComponent } from './pages/game-page/game-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { PlazaLibertadPageComponent } from './pages/plaza-libertad-page/plaza-libertad-page.component';
import { PrivacyPageComponent } from './pages/privacy-page/privacy-page.component';
import { SignToDonatePageComponent } from './pages/sign-to-donate-page/sign-to-donate-page.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { PuertoRicoPageComponent } from './pages/puerto-rico-page/puerto-rico-page.component';

const routes: Routes = [
  {
    path: 'terms',
    component: TermsPageComponent,
  },
  {
    path: 'privacy',
    component: PrivacyPageComponent,
  },
  {
    path: 'game',
    component: GamePageComponent,
  },
  {
    path: 'charity',
    component: CharityPageComponent,
  },
  {
    path: 'charity-enrollment',
    component: CharityEnrollmentPageComponent,
  },
  {
    path: 'sign-to-donate',
    component: SignToDonatePageComponent,
  },
  {
    path: 'about',
    component: AboutPageComponent,
  },
  {
    path: 'ambassador-enrollment',
    component: AmbassadorEnrollmentPageComponent,
  },
  {
    path: 'plaza-la-libertad',
    component: PlazaLibertadPageComponent,
  },
  {
    path: 'puerto-rico',
    component: PuertoRicoPageComponent,
  },
  {
    path: '',
    component: HomePageComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
