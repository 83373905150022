<section
  style="background-color: #9dde4e"
  class="block"
  data-pg-class-style="container"
  data-pg-name="Hero Charity Enrollment"
>
  <div
    id="page-charity-enrollment-top-section"
    class="flex flex-wrap justify-center items-center"
    data-pg-class-style="row"
    data-pg-class-style-inline=" items-center justify-center"
  >
    <div
      data-pg-class-style="column"
      class="lg:w-4/12 lg:mt-6 lg:mb-10 lg:ml-auto lg:mr-0 my-10 mx-auto md:w-4/12 md:ml-24 w-7/12"
      data-pg-class-style-inline=" lg:w-4/12 lg:mt-6 lg:mb-10 lg:ml-auto lg:mr-0 my-10 mx-auto md:w-4/12 md:ml-24 w-7/12"
    >
      <img
        src="../../../assets/images/Home2 Charity@3x-8.png"
        class="lg:h-auto lg:px-6 lg:w-10/12"
      />
    </div>
    <div
      class="lg:ml-6 lg:mr-auto lg:w-4/12 block order-last md:w-4/12 md:mr-20 w-9/12 my-auto"
      data-pg-class-style="column"
      data-pg-class-style-inline=" lg:ml-6 lg:mr-auto lg:w-4/12 block order-last md:w-4/12 md:mr-20 w-9/12 my-auto"
    >
      <h1
        data-pg-class-style="h2_title"
        class="lg:text-4xl lg:leading-tight leading-snug text-green-900 text-3xl mb-6 lg:w-5/6"
        style="font-family: 'Bitter', serif; font-weight: 700"
        data-pg-class-style-inline=" lg:w-5/6"
      >
        Pledge to donate
      </h1>
      <p
        data-pg-class-style="p_text"
        class="md:text-base text-base lg:text-base text-green-800 lg:mb-4 lg:w-5/6"
        style="font-family: 'Lato', sans-serif; font-weight: 700"
        data-pg-class-style-inline=" text-base lg:text-base lg:w-5/6"
      >
        Get ready for change!
      </p>
    </div>
  </div>
</section>
<div class="relative overflow-hidden mb-8" data-pg-dyn-id="did_77">
  <!-- <div data-pg-class-style="column">
    <h4
      style="font-family: 'Bitter', serif; font-weight: 700"
      class="mx-auto my-12 text-green-700 text-left text-xl w-9/12 lg:mx-auto lg:w-9/12 xl:ml-48"
    >
      Get Started
    </h4>
  </div> -->
  <div data-pg-class-style="column">
    <!-- <div>
      <div class="mb-24 mx-auto w-9/12 lg:w-9/12">
        <div id="charity-hubspot-form"></div>
      </div>
    </div> -->
    <div class="_form_10"></div>
    <!-- <script
      src="https://dollarzing.activehosted.com/f/embed.php?id=5"
      type="text/javascript"
      charset="utf-8"
    ></script> -->
  </div>
</div>
