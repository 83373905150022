import { Component, OnInit } from '@angular/core';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ViewChild, ElementRef, AfterViewInit } from '@angular/core';

let scripts = [
  'https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6455bbf16025fda390fea9dd',
  '../../../assets/puerto-rico/touchstart.js',
  '../../../assets/puerto-rico/webflow.js',
];
@Component({
  selector: 'app-puerto-rico-page',
  templateUrl: './puerto-rico-page.component.html',
  styleUrls: ['./puerto-rico-page.component.scss'],
})
export class PuertoRicoPageComponent implements OnInit, AfterViewInit {
  constructor(
    private renderer2: Renderer2,
    //@ts-ignore
    @Inject(DOCUMENT) private _document
  ) {}

  ngOnInit(): void {
    scripts.forEach((scriptLink) => {
      const s = this.renderer2.createElement('script');
      s.type = 'text/javascript';
      s.src = scriptLink;
      s.text = ``;
      this.renderer2.appendChild(this._document.body, s);
    });
  }

  // @ts-ignore
  @ViewChild('downloadNow') downloadNow: ElementRef;
  // @ts-ignore
  @ViewChild('betterTogether') betterTogether: ElementRef;
  ngAfterViewInit() {
    if (window.location.hash === '#download-now') {
      this.downloadNow.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    } else if (window.location.hash === '#better-together') {
      this.downloadNow.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }
}
