<div class="gradient-section section first-so-100vh wf-section">
  <div class="container w-container">
    <div data-w-id="78e64af3-f19b-1bc6-8293-24e5b7e6787d" class="hero-column">
      <!-- style="
        -webkit-transform: translate3d(-32px, 0px, 0px) scale3d(1, 1, 1)
          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
        -moz-transform: translate3d(-32px, 0px, 0px) scale3d(1, 1, 1) rotateX(0)
          rotateY(0) rotateZ(0) skew(0, 0);
        -ms-transform: translate3d(-32px, 0px, 0px) scale3d(1, 1, 1) rotateX(0)
          rotateY(0) rotateZ(0) skew(0, 0);
        transform: translate3d(-32px, 0px, 0px) scale3d(1, 1, 1) rotateX(0)
          rotateY(0) rotateZ(0) skew(0, 0);
        transform-style: preserve-3d;
        opacity: 0;
      " -->
      <div class="hero-column">
        <h1 class="hero-title gradient">Un mundo en el que todos ganan.</h1>
        <p class="main-paragraph">
          ¡Juega para ganar a lo grande y crear un impacto! Escanea tus dólares
          para aumentar el fondo comunitario y tener la oportunidad de ganar
          hasta $1,024 en un solo día mientras apoyas el crecimiento de la
          comunidad.
        </p>
      </div>
      <div class="actions">
        <a
          routerLink="/puerto-rico"
          fragment="download-now"
          class="button white"
        >
          <div class="text-28">DESCARGA AHORA</div>
        </a>
        <a
          routerLink="/puerto-rico"
          fragment="better-together"
          class="button-learn-more"
        >
          <div class="text-29">APRENDE MÁS</div>
        </a>
      </div>
    </div>
    <img
      class="app-image-2 first"
      src="../../../assets/images/puerto-rico/App-View-1.png"
      width="400"
      height="601"
      alt=""
      sizes="(max-width: 479px) 100vw, (max-width: 991px) 400px, 367.078125px"
      data-w-id="5878d8aa-7bb6-4b7c-18c1-3a6d1f271407"
      loading="lazy"
      srcset="
        ../../../assets/images/puerto-rico/App-View-1.png 500w,
        ../../../assets/images/puerto-rico/App-View-1.png 800w,
        ../../../assets/images/puerto-rico/App-View-1.png 838w
      "
    />
  </div>
</div>
<div class="section wf-section">
  <div class="cards-container w-container">
    <div class="title-section">
      <div class="title-text">Siente el Poder</div>
      <div class="subtitle-text">
        Al descargar la aplicación y registrarte, obtienes los poderes de
        DollarZing.
      </div>
    </div>
    <div class="columns cards">
      <div class="card">
        <div class="image-wrapper">
          <img
            src="../../../assets/images/puerto-rico/Illustration.png"
            loading="lazy"
            width="270"
            height="270"
            srcset="
              ../../../assets/images/puerto-rico/Illustration.png 500w,
              ../../../assets/images/puerto-rico/Illustration.png 540w
            "
            sizes="(max-width: 479px) 200px, 270px"
            alt=""
            class="illustration"
          />
        </div>
        <div class="content">
          <div class="info">
            <div class="card-title">Poder para Ganar</div>
          </div>
          <div class="card-description">
            Puedes retirar tus ganancias rápida y fácilmente a través de
            "PrizeOut" y obtener tarjetas de regalo de cientos de tus marcas
            favoritas.
          </div>
        </div>
      </div>
      <div class="card">
        <div class="image-wrapper">
          <img
            src="../../../assets/images/puerto-rico/Illustration-1.png"
            loading="lazy"
            width="270"
            height="270"
            srcset="
              ../../../assets/images/puerto-rico/Illustration-1.png 500w,
              ../../../assets/images/puerto-rico/Illustration-1.png 540w
            "
            sizes="(max-width: 479px) 200px, 270px"
            alt=""
            class="illustration"
          />
        </div>
        <div class="content">
          <div class="info">
            <div class="card-title">Poder para Crecer</div>
          </div>
          <div class="card-description">
            ¡Cada dólar que escaneas aumenta el fondo comunitario, dándote la
            oportunidad de ganar hasta $1,024 en un solo día!
          </div>
        </div>
      </div>
      <div class="card">
        <div class="image-wrapper">
          <img
            src="../../../assets/images/puerto-rico/Illustration-2.png"
            loading="lazy"
            width="270"
            height="270"
            srcset="
              ../../../assets/images/puerto-rico/Illustration-2.png 500w,
              ../../../assets/images/puerto-rico/Illustration-2.png 540w
            "
            sizes="(max-width: 479px) 200px, 270px"
            alt=""
            class="illustration"
          />
        </div>
        <div class="content">
          <div class="info">
            <div class="card-title">Poder para Dar</div>
          </div>
          <div class="card-description">
            DollarZing te brinda la oportunidad de donar el 10% o más a la
            organización sin fines de lucro de tu elección y hacer un gran
            impacto en la comunidad.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-2"></div>
</div>
<div id="download-now" class="download-section-1 section wf-section">
  <div class="container-3 w-container">
    <div class="title">Descarga ahora y únete a la acción.</div>
    <div class="download-buttons-container">
      <a
        href="https://apps.apple.com/us/app/dollarzing-ewallet-app/id1534206153?platform=iphone"
        target="_blank"
        class="w-inline-block"
        ><img
          src="../../../assets/images/puerto-rico/StoreApple-StyleGradient.svg"
          loading="lazy"
          width="300"
          alt=""
          class="download-button"
      /></a>
      <a
        href="https://play.google.com/store/apps/details?id=com.dollarzing.dollarzing&hl=en_US&gl=US"
        target="_blank"
        class="w-inline-block"
        ><img
          src="../../../assets/images/puerto-rico/StoreGoogle-StyleGradient.svg"
          loading="lazy"
          width="300"
          alt=""
          class="download-button"
      /></a>
    </div>
  </div>
</div>
<div id="better-together" class="section wf-section">
  <div class="cards-container w-container">
    <div class="title-section">
      <div class="title-text">Hacer del mundo un lugar mejor, juntos</div>
      <div class="subtitle-text">
        Cada participante de la plataforma se beneficia del juego DollarZing.
        ¡Todos ganan!
      </div>
    </div>
    <div class="columns cards">
      <div class="card">
        <img
          src="../../../assets/images/puerto-rico/Individuals.png"
          loading="lazy"
          width="270"
          height="270"
          alt=""
          class="illustration augusto"
        />
        <div class="content">
          <div class="info">
            <div class="card-title">Individuos</div>
          </div>
          <div class="card-description">
            Como individuo, puedes comenzar el juego con un dólar para tener la
            oportunidad de ganar hasta $1024 en un solo día. Luego, puedes donar
            un mínimo del 10% de estas ganancias a una organización benéfica de
            tu elección.
          </div>
        </div>
      </div>
      <div class="card">
        <img
          src="../../../assets/images/puerto-rico/Organizations.png"
          loading="lazy"
          width="270"
          height="270"
          alt=""
          class="illustration augusto"
        />
        <div class="content">
          <div class="info">
            <div class="card-title">Organizaciones</div>
          </div>
          <div class="card-description">
            Como organización sin fines de lucro, podrás establecer tus
            esfuerzos de marketing en nuestro portal con la oportunidad de
            recibir donaciones de nuestra comunidad de jugadores. El portal es
            tu punto de partida para hacer saber a nuestra comunidad acerca de
            tu misión y causa benéfica.
          </div>
        </div>
      </div>
      <div class="card">
        <img
          src="../../../assets/images/puerto-rico/Communities.png"
          loading="lazy"
          width="270"
          height="270"
          alt=""
          class="illustration augusto"
        />
        <div class="content">
          <div class="info">
            <div class="card-title">Comunidades</div>
          </div>
          <div class="card-description">
            Los patrocinadores pueden interactuar directamente con nuestros
            miembros a través de obsequios de sus productos y servicios. Ellos
            reciben interacción directa con nuestros miembros y exposición para
            sus productos y servicios a través de nuestro juego gratuito.
          </div>
        </div>
      </div>
    </div>
    <div class="actions newtosportsbetting"></div>
  </div>
  <div class="container-2"></div>
</div>
<div class="gradient-section section wf-section">
  <div class="container w-container">
    <img
      src="../../../assets/images/puerto-rico/App-View-2.png"
      loading="lazy"
      width="400"
      height="601"
      alt=""
      class="app-image-2 stacked"
    />
    <div class="hero-column">
      <div class="hero-column">
        <h1 class="hero-title gradient">Empezar</h1>
        <div class="steps">
          <div class="step-container">
            <div class="step-bubble">
              <div class="step-number">1</div>
            </div>
            <div class="step-text">
              <div class="step-title">Descarga la aplicación</div>
              <div class="step-description">
                Diviértete mientras haces el bien. La aplicación te da la
                oportunidad de empezar con $1 y recibir hasta $1024 del fondo
                compartido de la comunidad de dólares.
              </div>
            </div>
          </div>
          <div class="step-container">
            <div class="step-bubble">
              <div class="step-number">2</div>
            </div>
            <div class="step-text">
              <div class="step-title">Regístrate</div>
              <div class="step-description">
                Crea tu cuenta fácilmente con tu nombre completo y correo
                electrónico. Estás a un paso de empezar a ganar a lo grande y
                crear impactos positivos.
              </div>
            </div>
          </div>
          <div class="step-container">
            <div class="step-bubble">
              <div class="step-number">3</div>
            </div>
            <div class="step-text">
              <div class="step-title">Obtén $ZingCash</div>
              <div class="step-description">
                Antes de escanear tu primer $1, debes adquirir $ZingCash para
                recargar tu "billetera" y poder empezar el juego.
              </div>
            </div>
          </div>
          <div class="step-container">
            <div class="step-bubble">
              <div class="step-number">4</div>
            </div>
            <div class="step-text">
              <div class="step-title">
                Elige tu organización sin fines de lucro
              </div>
              <div class="step-description">
                Selecciona una de las 11 causas benéficas que más te guste o con
                la que te identifiques. Al elegir una, también podrás establecer
                el porcentaje que deseas donar a esa organización.
              </div>
            </div>
          </div>
          <div class="step-container">
            <div class="step-bubble">
              <div class="step-number">5</div>
            </div>
            <div class="step-text">
              <div class="step-title">Retira tus ganancias</div>
              <div class="step-description">
                "PrizeOut" es la forma rápida y divertida de retirar tus
                ganancias del juego y convertirlas en tarjetas de regalo para
                cientos de tus marcas favoritas.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="download-buttons-container-2-0 with-margin-top">
    <a
      href="https://apps.apple.com/us/app/dollarzing-ewallet-app/id1534206153?platform=iphone"
      target="_blank"
      class="w-inline-block"
      ><img
        src="../../../assets/images/puerto-rico/StoreApple-StyleWhite.svg"
        loading="lazy"
        width="300"
        alt=""
        class="download-button"
    /></a>
    <a
      href="https://play.google.com/store/apps/details?id=com.dollarzing.dollarzing&hl=en_US&gl=US"
      target="_blank"
      class="w-inline-block"
      ><img
        src="../../../assets/images/puerto-rico/StoreGoogle-StyleWhite.svg"
        loading="lazy"
        width="300"
        alt=""
        class="download-button"
    /></a>
  </div>
</div>
<section class="logos-title-small wf-section">
  <div class="container-5">
    <h1 class="hero-title white partners">Nuestros socios</h1>
    <div class="clients-wrapper-three">
      <img
        src="../../../assets/images/puerto-rico/Visit_Rico.png"
        loading="lazy"
        alt="AriseHealth logo"
        class="clients-image-three"
      /><img
        src="../../../assets/images/puerto-rico/Foundation_for_PR_Lo.png"
        loading="lazy"
        alt="OE logo"
        class="clients-image-three"
      /><img
        src="../../../assets/images/puerto-rico/Global_Fokus_Logo.png"
        loading="lazy"
        alt="2020INC logo"
        class="clients-image-three"
      /><img
        src="../../../assets/images/puerto-rico/BEN_PR_Logo.png"
        loading="lazy"
        alt="The Paak logo"
        class="clients-image-three"
      /><img
        src="../../../assets/images/puerto-rico/LimPiaR_Logo.jpg"
        loading="lazy"
        alt="Ephicient logo"
        class="clients-image-three"
      /><img
        src="../../../assets/images/puerto-rico/CTA_Logo.png"
        loading="lazy"
        alt="Ephicient logo"
        class="clients-image-three"
      /><img
        src="../../../assets/images/puerto-rico/Friends_of_PR_Logo_1.png"
        loading="lazy"
        alt="Ephicient logo"
        class="clients-image-three"
      /><img
        src="../../../assets/images/puerto-rico/EOTR_Logo.jpg"
        loading="lazy"
        alt="Ephicient logo"
        class="clients-image-three"
      /><img
        src="../../../assets/images/puerto-rico/Rescate_Playa_Borinq.jpg"
        loading="lazy"
        alt="Ephicient logo"
        class="clients-image-three"
      /><img
        src="../../../assets/images/puerto-rico/Museo_De_Los_Santos_.png"
        loading="lazy"
        alt="Ephicient logo"
        class="clients-image-three"
      /><img
        src="../../../assets/images/puerto-rico/BGCPR_Logo.jpg"
        loading="lazy"
        alt="Toogether logo"
        class="clients-image-three"
      />
    </div>
  </div>
</section>
<div class="section no-top-padding wf-section">
  <div class="download-buttons-container-2-0">
    <a
      href="https://apps.apple.com/us/app/dollarzing-ewallet-app/id1534206153?platform=iphone"
      target="_blank"
      class="w-inline-block"
      ><img
        src="../../../assets/images/puerto-rico/StoreApple-StyleBlack.svg"
        loading="lazy"
        width="300"
        alt=""
        class="download-button"
    /></a>
    <a
      href="https://play.google.com/store/apps/details?id=com.dollarzing.dollarzing&hl=en_US&gl=US"
      target="_blank"
      class="w-inline-block"
      ><img
        src="../../../assets/images/puerto-rico/StoreGoogle-StyleBlack.svg"
        loading="lazy"
        width="300"
        alt=""
        class="download-button"
    /></a>
  </div>
</div>
<script
  src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6455bbf16025fda390fea9dd"
  type="text/javascript"
  integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
  crossorigin="anonymous"
></script>
<script type="text/javascript">
  !(function (o, c) {
    var n = c.documentElement,
      t = " w-mod-";
    (n.className += t + "js"),
      ("ontouchstart" in o ||
        (o.DocumentTouch && c instanceof DocumentTouch)) &&
        (n.className += t + "touch");
  })(window, document);
</script>
<script
  src="../../../assets/scripts/webflow.js"
  type="text/javascript"
></script>
<!-- [if lte IE 9]><script src="https://cdnjs.cloudflare.com/ajax/libs/placeholders/3.0.2/placeholders.min.js"></script><![endif] -->
