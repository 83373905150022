<div id="page-home" class="page">
  <div class="section" id="section-1">
    <div id="carousel" class="splide">
      <div class="splide__track">
        <div class="splide__list">
          <!-- id tags aren't allowed on the slides as the splide library assigns its own id's -->
          <!-- NOTE: Splide 1 -->
          <div class="section-splide splide__slide slide-1 bg-gradient-green">
            <img
              class="image-iphone"
              src="../../../assets/images/DZ_iPhone PLAY 4 Dollar.png"
            />
            <div class="bg-base-green text-box">
              <p class="pre-header">Getting Started</p>
              <h1 class="header dummy-thicc">Welcome to DollarZing!</h1>
              <p class="body no-pad">
                Welcome to the world of Gifting & Receiving. We are a digital
                platform tied to a community concerned with social change and
                local impacts. From other peers like you, to charitable causes,
                everyone benefits from participating on the platform.
              </p>
            </div>
          </div>
          <!-- NOTE: Splide 2 -->
          <div class="section-splide section image splide__slide slide-2">
            <div class="image-for-mobile-special-stretch"></div>
            <div class="text-box bg-base-green">
              <p class="pre-header">THe App & Updates</p>
              <h1 class="header thick">Our App is live on the app stores</h1>
              <p class="body">
                DollarZing is now live on the app store! With this app our
                socially conscious gaming community has the capability to make
                <b>big</b> impacts through your microdonations. Together we can
                all be a part of a positive impact in our communities.
              </p>
              <div class="row-app-links">
                <a
                  class="link google-play"
                  href="https://play.google.com/store/apps/details?id=com.dollarzing.dollarzing"
                  target="_blank"
                >
                  <img
                    class="image google-play"
                    src="../../../assets/images/Button_Google_Play.svg"
                  />
                </a>
                <a
                  class="link app-store"
                  href="https://apps.apple.com/us/app/dollarzing-ewallet-app/id1534206153"
                  target="_blank"
                >
                  <img
                    class="image app-store"
                    src="../../../assets/images/Button_App_Store.svg"
                  />
                </a>
              </div>
            </div>
          </div>
          <!-- NOTE: Splide 3 -->
          <div class="section-splide section image splide__slide slide-3">
            <div class="image-for-mobile-special-stretch"></div>
            <div class="text-box bg-base-green">
              <p class="pre-header">Latest Impacts</p>
              <h1 class="header thick">
                Condado, Puerto Rico Plaza La Libertad
              </h1>
              <p class="body">
                Plaza La Libertad serves as an entrance to Condado. One block
                away from the beach, this beautiful structure has been abandoned
                and neglected for the past several years. DollarZing has teamed
                up with Global Fokus, Cromapolis, and the DRNA to revitalize the
                plaza and open it to the community. Once reopened, DollarZing
                and other organizations will have access to use the space for
                events, farmers markets, and more!
              </p>
              <!-- <a
                target="#"
                href="https://globalfokus.org/give/?framepath=%2F377%3Fframe%3Dtrue"
              >
                <button class="button one">Donate Now</button> </a
              > -->
              <!-- <div class="button-align"> -->
              <a routerLink="/plaza-la-libertad">
                <button class="button two">Learn More</button>
              </a>
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="section-2" class="section">
    <img class="side-image" src="../../../assets/images/Home Trees Bench.png" />

    <div class="text-box">
      <p class="pre-header">About us</p>
      <h1 class="header">A culture of Gifting & Receiving</h1>
      <p class="body no-pad">
        DollarZing is a digital platform that enables gifting and receiving,
        amongst the member community, for the purpose of doing good today.
        Whether between single members, local charities, or contributors to
        charities, gifting and receiving is the concept of our culture and
        purpose.
      </p>
      <a routerLink="/about">
        <button class="button">Get to know us!</button>
      </a>
    </div>
  </div>
  <div id="section-3" class="section bg-gradient-green">
    <img
      class="image-iphone"
      src="../../../assets/images/DZ_iPhone ZIngCash All.png"
    />
    <div class="bg-base-green text-box">
      <p class="pre-header">The App</p>
      <h1 class="header normal">
        Get to know DollarZing through our app platform.
      </h1>
      <p class="body no-pad">
        The App is the management portal for our members. It contains the
        DollarZing game, the charity manager, and the ZingCash Wallet. Gifts
        received from the game can be managed on the App as well as
        communication within the ecosystem.
      </p>
      <a routerLink="/game">
        <button class="button">Learn More</button>
      </a>
    </div>
  </div>
  <!-- <div class="whitespace always"></div> -->
  <!-- NOTE: How it works -->
  <div id="section-how-it-works" class="section">
    <div class="text-box">
      <h1 class="header thick">How it works:</h1>
      <h1 class="subheader">Press play to learn more about us</h1>
    </div>
    <video
      id="video-dollar-zing"
      src="../../../assets/videos/Dollar Zing-FinalCut (1).mp4"
      poster="../../../assets/videos/DZ_Website VIDEO Frame v1-01.png"
      controls=""
    >
      Your browser doesn't support HTML5 video tag.
    </video>
  </div>
  <div id="section-4" class="section image">
    <img
      class="image-for-mobile"
      src="../../../assets/images/Home Puerto Rico Flag.png"
    />
    <div class="text-box bg-base-green">
      <p class="pre-header">Charitable Impacts</p>
      <h1 class="header normal">DollarZing & Friends of Puerto Rico</h1>
      <p class="body no-pad">
        See your donations make an impact with local charities like Friends of
        Puerto Rico. Friends has been helping children 9-12 and women develop
        the entrepreneurship skills needed to develop their own businesses. They
        have been established since 2015, in the midst of a torrential economic
        time for Puerto Rico. Helping Puerto Ricans along the way, their impacts
        have helped raise over $1 million for Hurricane Maria.
      </p>
      <a href="https://www.friendsofpuertorico.org/" target="_blank">
        <button class="button">Learn More</button>
      </a>
    </div>
  </div>

  <div class="whitespace always"></div>
</div>
