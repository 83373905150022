import { Component, OnInit } from '@angular/core';
import { scrollToTop } from 'src/assets/utils/scrollToTop';

@Component({
  selector: 'app-game-page',
  templateUrl: './game-page.component.html',
  styleUrls: ['./game-page.component.scss'],
})
export class GamePageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    scrollToTop();
  }
}
