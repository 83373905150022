import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ActiveCampaignService } from 'src/app/services/active-campaign.service';
import { scrollToTop } from 'src/assets/utils/scrollToTop';

const SCRIPT_PATH = 'https://dollarzing.activehosted.com/f/embed.php?id=5';
// declare let gapi: any;

@Component({
  selector: 'app-charity-enrollment-page',
  templateUrl: './charity-enrollment-page.component.html',
  styleUrls: ['./charity-enrollment-page.component.scss'],
})
export class CharityEnrollmentPageComponent implements OnInit {
  constructor(
    private renderer: Renderer2,
    private activeCampaignService: ActiveCampaignService
  ) {}

  ngOnInit(): void {
    this.activeCampaignService.loadJsScript(this.renderer, 'charity');
    // scriptElement.onload = () => {
    //   console.log('Active Campaign Script loaded');
    //   // console.log(gapi);

    //   // Load the JavaScript client library.
    //   // (the init() method has been omitted for brevity)
    //   // gapi.load('client', this.init);
    //   // gapi.load()
    // };
    // scriptElement.onerror = () => {
    //   console.log('Could not load the Active Campaign Script!');
    // };
    scrollToTop();

    // const s = this.renderer2.createElement('script');
    // s.onload = this.loadNextScript.bind(this);
    // s.type = 'text/javascript';
    // s.src = 'https://js.hsforms.net/forms/shell.js';
    // s.text = ``;
    // this.renderer2.appendChild(this._document.body, s);
  }

  //   loadNextScript() {
  //     const s = this.renderer2.createElement('script');
  //     s.text = `
  //    hbspt.forms.create({
  // 	portalId: "8774320",
  // 	formId: "56041d6b-bb5e-48c3-a048-5a729df3a0ec"
  // });
  // `;
  //     this.renderer2.appendChild(this._document.body, s);
  //   }

  // ngAfterViewInit() {
  // @ts-ignore
  // hbspt.forms.create({
  //   portalId: '8774320',
  //   formId: '56041d6b-bb5e-48c3-a048-5a729df3a0ec',
  //   target: '#charity-hubspot-form',
  // });
  // window.scrollTo(0, 0);
  // }
}
