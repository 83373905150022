<div class="page charity-page">
  <section>
    <div id="section-1" class="section charity-page section-image full header">
      <img
        class="image-for-mobile"
        src="../../../assets/temp-images/charity-header.png"
      />
      <div class="bg-base-green" id="section-text-1">
        <p class="pre-header">Getting Started</p>
        <h1 class="header">Impacts at the Local Level</h1>
        <p class="body">
          DollarZing is building a platform to help charities establish their
          identities and market their causes to our broader community members.
          Eventually, charities will be able to manage ZingCash donations and
          create campaigns to expand the reach of their impacts.
        </p>
      </div>
    </div>
  </section>
  <section>
    <div id="section-2" class="section section-2">
      <!-- <div class="column-spacing-left"></div> -->
      <div class="column column-text flex flex-col justify-center">
        <div>
          <p class="pre-header">Member Player Contribution</p>
          <h1 class="header">From Peers to Charities</h1>
          <p class="body body-1 no-pad">
            DollarZing will bring together local and international charities
            into the peer-to-peer community. As a source of receiving from our
            peers, these charity organizations then gift to their local causes.
            <br /><br />
            As a member, you will be able to see the community impacts that your
            gifted dollars have created. Every time you play DollarZing, you
            automatically contribute to our charity member's causes. Everyone
            participates in gifting and recieving.
          </p>
        </div>
      </div>
      <div class="column column-spacing-middle"></div>
      <div class="column column-image flex flex-col justify-center">
        <img src="../../../assets/temp-images/charity-hands-heart.png" />
      </div>
      <div class="column column-spacing-right"></div>
    </div>
  </section>
  <section>
    <div id="section-3" class="section section-3 bg-white">
      <div class="image-seaside"></div>
      <img
        class="image-for-mobile"
        src="../../../assets/temp-images/charity-beach.png"
      />
      <div class="flex flex-row justify-start">
        <div class="spacing-left"></div>
        <div class="flex flex-col justify-start">
          <div class="spacing-top"></div>
          <div class="text-box">
            <p class="pre-header">Starting Together</p>
            <h1 class="header">Our Focus for 2022 is on Puerto Rico</h1>
            <p class="body body-1">
              We will work to bring resources to the people of Puerto Rico and
              build a community of gifting and receiving through our spnsored
              events and community outreach.
            </p>
          </div>
          <div id="item-container">
            <div
              id="item-column-1"
              class="item-column item-column-1 flex flex-col"
            >
              <a href="https://foundationforpuertorico.org/en/" target="#">
                <div
                  class="charity-resource-item flex flex-row items-center mb-10"
                >
                  <img
                    class="image"
                    src="../../../assets/temp-images/logos/foundation-for-puerto-rico.png"
                  />
                  <h1
                    class="font-cholla-slab-ot-no-weight leading-snug text-lg pb-0"
                    style="font-weight: 900"
                  >
                    Foundation for <br />
                    Puerto Rico
                  </h1>
                </div></a
              >

              <!-- <a href=""></a> -->
              <!-- <div
                class="charity-resource-item flex flex-row items-center mb-10"
              >
                <img
                  class="image"
                  src="../../../assets/images/logos/logo"
                />
                <h1
                  class="font-cholla-slab-ot-no-weight leading-snug text-lg pb-0"
                  style="font-weight: 900"
                >
                  Integro?
                </h1>
              </div> -->
              <a href="https://www.ctahorse.com/" target="#">
                <div
                  class="charity-resource-item flex flex-row items-center mb-10"
                >
                  <img
                    class="image"
                    src="../../../assets/temp-images/logos/carribean-thoroughbred-aftercare.png"
                  />
                  <h1
                    class="font-cholla-slab-ot-no-weight leading-snug text-lg pb-0"
                    style="font-weight: 900"
                  >
                    Carribean <br />
                    Thoroughbred <br />
                    Aftercare
                  </h1>
                </div></a
              >
              <a href="https://bgcpr.org/en/" target="#">
                <div
                  class="charity-resource-item flex flex-row items-center mb-10"
                >
                  <img
                    class="image"
                    src="../../../assets/temp-images/logos/boys-and-girls-club.png"
                  />
                  <h1
                    class="font-cholla-slab-ot-no-weight leading-snug text-lg pb-0"
                    style="font-weight: 900"
                  >
                    Boys & Girls Club of <br />
                    Puerto Rico
                  </h1>
                </div></a
              >
              <a href="https://www.visitrico.org/" target="#">
                <div
                  class="charity-resource-item flex flex-row items-center mb-10"
                >
                  <img
                    class="image"
                    src="../../../assets/images/logos/logo-rico.jpg"
                  />
                  <h1
                    class="font-cholla-slab-ot-no-weight leading-snug text-lg pb-0"
                    style="font-weight: 900"
                  >
                    Visit Rico
                  </h1>
                </div></a
              >
              <a href="https://rescateplayasborinquen.org/" target="#">
                <div
                  class="charity-resource-item flex flex-row items-center mb-10"
                >
                  <img
                    class="image"
                    src="../../../assets/images/logos/logo-borinquen.jpg"
                  />
                  <h1
                    class="font-cholla-slab-ot-no-weight leading-snug text-lg pb-0"
                    style="font-weight: 900"
                  >
                    Rescate Playa<br />
                    Borinquen
                  </h1>
                </div>
              </a>
            </div>

            <div class="flex flex-col">
              <a href="https://www.friendsofpuertorico.org/" target="#">
                <div
                  class="charity-resource-item flex flex-row items-center mb-10"
                >
                  <img
                    class="image"
                    src="../../../assets/temp-images/logos/friends-of-puerto-rico.png"
                  />
                  <h1
                    class="font-cholla-slab-ot-no-weight leading-snug text-lg pb-0"
                    style="font-weight: 900"
                  >
                    Friends of <br />
                    Puerto Rico
                  </h1>
                </div></a
              >
              <a href="https://globalfokus.org/" target="#">
                <div
                  class="charity-resource-item flex flex-row items-center mb-10"
                >
                  <img
                    class="image"
                    src="../../../assets/temp-images/logos/global-fokus.png"
                  />
                  <h1
                    class="font-cholla-slab-ot-no-weight leading-snug text-lg pb-0"
                    style="font-weight: 900"
                  >
                    Global Fokus
                  </h1>
                </div></a
              >
              <a href="https://eyeontherainforest.org/" target="#">
                <div
                  class="charity-resource-item flex flex-row items-center mb-10"
                >
                  <img
                    class="image"
                    src="../../../assets/images/logos/logo-eotr.jpg"
                  />
                  <h1
                    class="font-cholla-slab-ot-no-weight leading-snug text-lg pb-0"
                    style="font-weight: 900"
                  >
                    Eye on the<br />
                    Rainforest
                  </h1>
                </div></a
              >
              <a href="https://www.blockchainedu.org/" target="#">
                <div
                  class="charity-resource-item flex flex-row items-center mb-10"
                >
                  <img
                    class="image"
                    src="../../../assets/images/logos/logo-ben-2.png"
                  />
                  <h1
                    class="font-cholla-slab-ot-no-weight leading-snug text-lg pb-0"
                    style="font-weight: 900"
                  >
                    Blockchain <br />
                    Education <br />
                    Network
                  </h1>
                </div></a
              >
              <a href="https://musan.org/en/" target="#">
                <div
                  class="charity-resource-item flex flex-row items-center mb-10"
                >
                  <img
                    class="image"
                    src="../../../assets/images/logos/logo-musan.png"
                  />
                  <h1
                    class="font-cholla-slab-ot-no-weight leading-snug text-lg pb-0"
                    style="font-weight: 900"
                  >
                    Museo De Los<br />Santos
                  </h1>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div id="section-4" class="section">
      <img
        class="image-for-mobile"
        src="../../../assets/temp-images/charity-connect-4.png"
      />
      <div class="text-box over-image bg-base-green">
        <p class="pre-header">Dollarzing Charities</p>
        <div class="logo-and-header flex flex-row items-center">
          <img
            src="../../../assets/temp-images/logos/boys-and-girls-club.png"
          />
          <h1 class="header">
            Working to help kids from low-income communities
          </h1>
        </div>
        <p class="body">
          Living in poverty means that young people grow up in families that do
          not have enough income to cover their basic needs. As a result, they
          do not develop the necessary skills to meet their personal and
          professional goals in order to escape poverty. DollarZing is commited
          to supporting the Boys & Girls Club of Puerto Rico to help raise the
          standard of life for kids through its member donations.
        </p>
        <div class="button-row pt-6">
          <a href="https://bgcpr.org/en/" target="_blank">
            <button class="button">Learn More</button>
          </a>
        </div>
      </div>
    </div>
  </section>
  <div class="whitespace"></div>
  <section>
    <div id="section-5" class="bg-base-green px-24 pt-36 pb-28">
      <h1 class="header font-cholla-slab-ot-no-weight">
        Want to become a DollarZing charity and receive resources for your
        cause?
      </h1>
      <div class="mb-12"></div>
      <div class="flex flex-row justify-start pt-6">
        <a routerLink="/charity-enrollment">
          <button class="button">Apply Here</button>
        </a>
      </div>
      <div class="mb-36"></div>
      <div id="three-items">
        <div class="div application-info flex flex-col items-center">
          <img src="../../../assets/temp-images/icons/community.png" />
          <div class="mb-8"></div>
          <h1 class="font-cholla-slab-ot-no-weight">JOIN A COMMUNITY</h1>
          <div class="mb-7"></div>
          <p class="text-md font-lato-no-weight">
            The Charity Portal is a hub to communicate your cause, your
            organization's details, events, and general exposure of your
            activities. This will be a place to establish a presence for your
            cause.
          </p>
        </div>
        <div class="div application-info flex flex-col items-center">
          <img src="../../../assets/temp-images/icons/browsertabs.png" />
          <div class="mb-8"></div>
          <h1 class="font-cholla-slab-ot-no-weight">MARKET YOUR CAUSE</h1>
          <div class="mb-7"></div>
          <p class="text-md font-lato-no-weight">
            As a repository for your cause, the Charity Portal has the ability
            to launch event invites, post pictures and video, and share content
            with other Social media. This is your first step in promoting a
            cause for member donations.
          </p>
        </div>
        <div class="div application-info flex flex-col items-center">
          <img src="../../../assets/temp-images/icons/money.png" />
          <div class="mb-8"></div>
          <h1 class="font-cholla-slab-ot-no-weight">ATTRACT MEMBER GIFTS</h1>
          <div class="mb-7"></div>
          <p class="text-md font-lato-no-weight">
            Because the Charity Portal is connected to the DollarZing App,
            members can choose a specific charity to donate to. These gifts from
            members are mandatory and offer some resources for your activities.
          </p>
        </div>
      </div>
    </div>
  </section>
  <div class="whitespace"></div>
  <section>
    <div id="section-6" class="section">
      <img
        class="image-for-mobile"
        src="../../../assets/temp-images/charity-kids.png"
      />
      <div class="text-box over-image bg-base-green">
        <p class="pre-header">International Impacts</p>
        <h1 class="header">DollarZing & KarmaKarma for World Impacts</h1>
        <p class="body">
          DollarZing has partnered with KarmaKarma to bring the DollarZing
          community to charitable services located across the globe.
        </p>
        <a href="http://www.karmakarma.com/" target="_blank">
          <button class="button">Donate</button>
        </a>
      </div>
    </div>
  </section>
  <div class="whitespace"></div>
</div>
