import { Injectable } from '@angular/core';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

type choice = 'charity' | 'ambassador' | 'signToDonate';
let links = {
  charity: 'https://dollarzing.activehosted.com/f/embed.php?id=5',
  ambassador: 'https://dollarzing.activehosted.com/f/embed.php?id=7',
  signToDonate: 'https://dollarzing.activehosted.com/f/embed.php?id=10',
};

@Injectable({
  providedIn: 'root',
})
export class ActiveCampaignService {
  choice!: 'charity' | 'ambassador' | 'signToDonate';
  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * Append the JS tag to the Document Body.
   * @param renderer The Angular Renderer
   * @param src The path to the script
   * @returns the script element
   */
  public loadJsScript(renderer: Renderer2, choice: choice): HTMLScriptElement {
    const script = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = links[choice];
    renderer.appendChild(this.document.body, script);
    return script;
  }
}
