import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  hideLinks: boolean = true;
  scrollAtTop: boolean = true;

  @Input() makeTransparent = true;

  constructor() {}

  ngOnInit(): void {
    console.log(document.body.scrollTop);
    document.addEventListener('scroll', (e) => {
      if (document.documentElement.scrollTop !== 0) {
        this.scrollAtTop = false;
        // console.log('no longer at top');
      } else {
        this.scrollAtTop = true;
        // console.log('at top');
      }
    });
  }

  toggleLinks() {
    this.hideLinks = !this.hideLinks;
  }
}
