import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ambassador',
  templateUrl: './ambassador.component.html',
  styleUrls: ['./ambassador.component.scss'],
})
export class AmbassadorComponent implements OnInit {
  hide: boolean = false;

  constructor(private location: Location) {
    location.onUrlChange((val) => {
      if (
        val === '/terms' ||
        val === '/privacy' ||
        val === '/charity-enrollment' ||
        val === '/ambassador-enrollment' ||
        val === '/plaza-la-libertad' ||
        val === '/sign-to-donate'
      ) {
        this.hide = true;
      } else {
        this.hide = false;
      }
    });
  }

  ngOnInit(): void {}
}
