<nav id="navbar" [class]="{ transparent: makeTransparent && scrollAtTop }">
  <div class="top-row-on-mobile">
    <div class="nav-section logo">
      <a class="router-link" routerLink="/">
        <img id="logo" src="../../../assets/images/DZ_LOGO_Inline@4x-8.png" />
        <img
          id="logo-light"
          src="../../../assets/images/logo-light-dark.png"
          [class]="{ show: makeTransparent && scrollAtTop }"
        />
      </a>
    </div>
    <div class="nav-section button-for-links">
      <button
        (click)="toggleLinks()"
        id="button-for-links"
        class="hamburger button"
        [class.active]="!hideLinks"
      >
        <div class="hamburger-line"></div>
        <div class="hamburger-line"></div>
        <div class="hamburger-line"></div>
      </button>
    </div>
  </div>

  <div class="nav-section links" [class.hide-pop-down]="hideLinks">
    <a (click)="toggleLinks()" class="router-link" routerLink="/about">
      About
    </a>
    <a (click)="toggleLinks()" class="router-link" routerLink="/game">
      The Game
    </a>
    <a (click)="toggleLinks()" class="router-link" routerLink="/charity">
      Impacts
    </a>
  </div>
</nav>
