import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActiveCampaignService } from 'src/app/services/active-campaign.service';
import { scrollToTop } from 'src/assets/utils/scrollToTop';

@Component({
  selector: 'app-sign-to-donate-page',
  templateUrl: './sign-to-donate-page.component.html',
  styleUrls: ['./sign-to-donate-page.component.scss'],
})
export class SignToDonatePageComponent implements OnInit {
  constructor(
    private renderer: Renderer2,
    private activeCampaignService: ActiveCampaignService
  ) {}

  ngOnInit(): void {
    this.activeCampaignService.loadJsScript(this.renderer, 'signToDonate');
    scrollToTop();
  }
}
