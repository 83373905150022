<footer
  class="bg-green-700"
  data-pgc-define="DZ.Footer"
  data-pgc-define-name="footer"
  data-pgc-define-description="General footer for DZ Web v3 2020"
  data-pgc-define-photo-preview-only=""
>
  <div data-pg-class-style="container" class="block">
    <div
      class="flex flex-wrap lg:items-center justify-between"
      data-pg-class-style="row"
      data-pg-class-style-inline=" lg:items-center justify-between"
    >
      <div
        class="lg:w-5/12 lg:pl-0 lg:pr-24 lg:mt-4 lg:ml-24 xl:ml-32 lg:justify-between lg:items-center"
        data-pg-class-style="column"
        data-pg-class-style-inline=" lg:w-5/12 lg:pl-0 lg:pr-24 lg:mt-4 lg:ml-24 xl:ml-32 lg:justify-between lg:items-center"
      >
        <img
          src="../../../assets/images/DZ_LOGO_Diff_Green-01.png"
          data-pg-name="Logo"
          class="mb-0 mt-12 mx-auto w-9/12 md:w-6/12 lg:-ml-3 lg:mt-8 lg:w-11/12 xl:w-8/12"
        />
      </div>
      <div
        data-pg-class-style="column"
        class="lg:w-4/12"
        data-pg-class-style-inline=" lg:w-4/12"
      ></div>
    </div>
    <div
      data-pg-class-style="row"
      class="flex flex-wrap lg:items-center justify-around items-center lg:justify-between"
      data-pg-class-style-inline=" lg:items-center justify-around items-center lg:justify-between"
    >
      <div
        data-pg-class-style="column"
        class="xl:ml-32 lg:pr-6 lg:w-4/12 lg:mt-4 lg:ml-24"
        data-pg-class-style-inline=" xl:ml-32 lg:pr-6 lg:w-4/12 lg:mt-4 lg:ml-24"
      >
        <div
          class="flex flex-wrap justify-center items-start lg:my-4 lg:items-center lg:justify-start"
          data-pg-class-style="row"
          data-pg-class-style-inline=" items-start lg:my-4 lg:items-center lg:justify-start justify-center"
          data-pg-name="Social Icons"
        >
          <!-- Facebook Icon -->
          <a target="#" href="https://www.facebook.com/dollarzing/">
            <i
              class="fa-2x fa-facebook-square fab mx-4 text-green-100 lg:ml-0"
            ></i>
          </a>
          <!-- Instagram Icon -->

          <a target="#" href="https://www.instagram.com/dollarzing/">
            <i class="fa-2x fa-instagram fab mx-4 text-green-100"></i>
          </a>
          <!-- LinkedIn Icon -->
          <a target="#" href="https://www.linkedin.com/company/dollarzing/">
            <i class="fa-2x fa-linkedin fab mx-4 text-green-100"></i
          ></a>
        </div>
        <div
          class="flex flex-wrap justify-center h-3 lg:items-center lg:justify-start mt-6"
          data-pg-class-style="row"
          data-pg-class-style-inline=" h-3 lg:items-center lg:justify-start justify-center mt-6"
        >
          <a
            routerLink="/privacy"
            style="font-family: 'Lato', sans-serif; font-weight: 700"
            class="h-auto text-green-200 lg:text-sm"
            >Privacy Policy
          </a>
          <div class="h-2 w-2">
            <p
              class="text-center text-green-100"
              style="font-family: 'Lato', sans-serif; font-weight: 700"
            >
              |
            </p>
          </div>
          <a
            routerLink="/terms"
            class="h-auto text-green-200 lg:text-sm"
            style="font-family: 'Lato', sans-serif; font-weight: 700"
            >Terms &amp; Conditions</a
          >
        </div>
      </div>
      <div
        data-pg-class-style="column"
        class="lg:w-4/12 w-10/12 order-first lg:order-last mt-8 mb-16 lg:mb-6 lg:mr-24 xl:mr-24"
        data-pg-class-style-inline=" lg:w-4/12 w-10/12 order-first lg:order-last mt-8 mb-16 lg:mb-6 lg:mr-24 xl:mr-24"
      >
        <a
          routerLink="/game"
          class="block my-4 text-center text-green-100 lg:block lg:text-right"
          style="font-family: 'Lato', sans-serif; font-weight: 700"
          >The Game</a
        >
        <a
          routerLink="/charity"
          class="block my-4 text-center text-green-100 lg:block lg:text-right"
          style="font-family: 'Lato', sans-serif; font-weight: 700"
          >Zing Charities</a
        >
        <a
          routerLink="/charity-enrollment"
          class="block my-4 text-center text-green-100 lg:block lg:text-right"
          style="font-family: 'Lato', sans-serif; font-weight: 700"
          >Charity Sign-Up</a
        >
      </div>
    </div>
    <div
      class="flex flex-wrap justify-center lg:items-center"
      data-pg-class-style="row"
      data-pg-class-style-inline=" lg:items-center"
    >
      <div
        class="items-center xl:ml-32 mb-8 mx-auto lg:w-5/12 lg:ml-24"
        data-pg-class-style="column"
        data-pg-class-style-inline=" items-center xl:ml-32 mb-8 mx-auto lg:w-5/12 lg:ml-24"
      >
        <p class="text-center text-green-300 text-xs lg:mb-8 lg:text-left">
          Copyright © 2021 DollarZing, LLC. All rights reserved
        </p>
      </div>
      <div class="mb-8 lg:mr-24 lg:w-4/12"></div>
    </div>
  </div>
</footer>
