<div id="page-game" class="page">
  <!-- NOTE: Section 1 -->
  <section>
    <div id="section-1" class="section bg-gradient-green">
      <img
        class="image-iphone"
        src="../../../assets/temp-images/game-iphone-1.png"
      />
      <div class="bg-base-green text-box">
        <p class="pre-header">The App & Peer-To-Peer Game</p>
        <h1 class="header thick">Having Fun While Giving Back</h1>
        <p class="body">
          The DollarZing challenge starts with a $1 USD Bill to play a
          peer-to-peer money gifting game for our community members to
          potentially receive a larger sum of shared dollars. The game then
          creates a fund for quality charitable causes at the local, national
          and international levels.
        </p>
      </div>
    </div>
  </section>
  <!-- NOTE: Section 2 -->
  <section>
    <div id="section-2" class="section">
      <div class="portion-top">
        <div class="text-box">
          <p class="pre-header">The DollarZing Currency</p>
          <h1 class="header thick">Convert US Dollars to $ZingCash</h1>
          <p class="body">
            $ZingCash is the online currency used to play DollarZing. Members
            play, donate, and purchase using $ZingCash on our private labeled
            platform. Members exchange dollars from their financial institution
            for $ZingCash equivalent dollars on the mobile platform.
          </p>
        </div>
        <img
          class="image-iphone"
          src="../../../assets/temp-images/game-iphone-zing-cash.png"
        />
      </div>
      <div class="portion-bottom">
        <div class="three-items">
          <div class="div application-info flex flex-col items-center">
            <div class="image-container">
              <img src="../../../assets/temp-images/icons/upload.png" />
            </div>
            <div class="mb-8"></div>
            <h1 class="font-cholla-slab-ot-no-weight">Used To Play</h1>
            <div class="mb-7"></div>
            <p class="description">
              The Charity Portal is a hub to communicate your cause, your
              organization's details, events, and general exposure of your
              activities. This will be a place to establish a presence for your
              cause.
            </p>
          </div>
          <div class="div application-info middle flex flex-col items-center">
            <div class="image-container">
              <img src="../../../assets/temp-images/icons/check.png" />
            </div>
            <div class="mb-8"></div>
            <h1 class="font-cholla-slab-ot-no-weight">Used To Donate</h1>
            <div class="mb-7"></div>
            <p class="description">
              As a repository for your cause, the Charity Portal has the ability
              to launch event invites, post pictures and video, and share
              content with other Social media. This is your first step in
              promoting a cause for member donations.
            </p>
          </div>
          <div class="div application-info flex flex-col items-center">
            <div class="image-container">
              <img src="../../../assets/temp-images/icons/dollars.png" />
            </div>
            <div class="mb-8"></div>
            <h1 class="font-cholla-slab-ot-no-weight">Value Exchange</h1>
            <div class="mb-7"></div>
            <p class="description">
              Because the Charity Portal is connected to the DollarZing App,
              members can choose a specific charity to donate to. These gifts
              from members are mandatory and offer some resources for your
              activities.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- NOTE: Section 3 -->
  <section>
    <div id="section-3" class="section bg-base-green">
      <!-- NOTE: Section 3, Row 1 -->
      <div class="row one">
        <div class="text-box">
          <p class="pre-header">Game Play</p>
          <h1 class="header thick">It all starts with a $1 Dollar Bill</h1>
          <p class="body">
            The DollarZing game is played in two forms: for cash or for
            practice, which could lead to sponsored gifts or discounts to our
            members. Here we explain the basics of game play.
          </p>
        </div>
        <img src="../../../assets/temp-images/game-man-1.png" />
      </div>
      <!-- NOTE: Section 3, Row 2 -->
      <div class="row two">
        <img src="../../../assets/temp-images/game-iphone-tap-to-play.png" />
        <div class="text-box">
          <h1 class="header thin">
            DollarZing is easy to play as the rules are fairly straightforward:
          </h1>
          <ul class="body-list" type="circle">
            <li>
              A member uses a US $1 dollar bill’s unique serial number for every
              game play.
            </li>
            <li>
              One member is paired with another member of equal value shared.
            </li>
            <li>
              Members share a maximum of 10 $1 Bills per day to potentially
              receive $1024.00 per day.
            </li>
            <li>Sign-up with age verification(KYC) of 18 yrs.</li>
            <li>
              Purchase $ZingCash to share, receive cash or play for free and
              earn virtual $ZingCash.
            </li>
            <li>
              Our members agree to donate a minimum of 10% of all $ZingCash
              received.
            </li>
          </ul>
        </div>
      </div>
      <!-- NOTE: Section 3, Row 3 -->
      <div class="row three">
        <div class="col">
          <img
            class="pre-text-image"
            src="../../../assets/temp-images/game-man-snapping.png"
          />
          <div class="text-box">
            <p class="pre-header">Game Play 01</p>
            <h1 class="header thin">Snapping a Picture of a Dollar</h1>
            <p class="body no-pad">
              The first step is to snap a picture of the serial number of a
              dollar:
              <br /><br />
            </p>
            <ul class="body-list no-pad" type="circle">
              <li>
                A player snaps a photo of a $1 bill and the serial number is
                scanned into our proprietary algorithm to start the gifting
                game.
              </li>
              <li>
                The game then looks for a player at the same level of sharing
                from $1 to $512.
              </li>
            </ul>
          </div>
        </div>
        <img src="../../../assets/temp-images/game-iphone-2.png" />
      </div>
      <div class="row four">
        <img src="../../../assets/temp-images/game-iphone-players-join.png" />
        <div class="col">
          <img
            class="pre-text-image"
            src="../../../assets/temp-images/game-man-players-join.png"
          />
          <div class="text-box">
            <p class="pre-header">Game Play 02</p>
            <h1 class="header thin">Players Join and Play</h1>
            <p class="body">
              Next, a player enters the game and presses the center Zing button.
              <br /><br />
            </p>
            <ul class="body-list" type="circle">
              <li>
                A second player enters the game and shares his/ her dollar.
              </li>
              <li>
                The outcome of the game is deterined by that day’s engine
                algorythm for all dollar-serial numbers.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row five">
        <div class="col">
          <img
            class="pre-text-image"
            src="../../../assets/temp-images/game-man-gifting.png"
          />
          <div class="text-box">
            <p class="pre-header">Game Play 03</p>
            <h1 class="header thin">Gifting and Receiving</h1>
            <ul class="body-list no-pad" type="circle">
              <li>
                The game is played by sending the serial numbers to our
                decentralized Hedera Platform to determine the highest value $1
                bills unique serial number algorithm.
              </li>
              <li>
                The winning $1 becomes $2 and can continue to be played 10 more
                times doubling every time to reach $1024.00 or deposited back to
                your $ZingCash Wallet.
              </li>
            </ul>
          </div>
        </div>
        <img src="../../../assets/temp-images/game-iphone-gifting.png" />
      </div>
    </div>
  </section>
  <!-- NOTE: Section 4 -->
  <section>
    <div id="section-4" class="section">
      <div class="portion-top">
        <div class="text-box">
          <p class="pre-header">The Place To Give</p>
          <h1 class="header thick">The Charity Portal</h1>
          <p class="body">
            All donating activities and management are done within the Charity
            Portal of the App. The balance retained , current charity chosen,
            and other details allow members to support their local and national
            charities.
          </p>
        </div>
        <img
          class="image-iphone"
          src="../../../assets/temp-images/game-iphone-charity.png"
        />
      </div>
      <div class="portion-bottom">
        <div class="three-items">
          <div class="div application-info flex flex-col items-center">
            <div class="image-container">
              <img src="../../../assets/temp-images/icons/upload.png" />
            </div>
            <div class="mb-8"></div>
            <h1 class="font-cholla-slab-ot-no-weight">Used to set limits</h1>
            <div class="mb-7"></div>
            <p class="description">
              Using a scroll bar, you can set the minimum gifting percentage of
              earnings from game play. The minimum is 10%.
            </p>
          </div>
          <div class="div application-info middle flex flex-col items-center">
            <div class="image-container">
              <img src="../../../assets/temp-images/icons/check.png" />
            </div>
            <div class="mb-8"></div>
            <h1 class="font-cholla-slab-ot-no-weight">Used To Browse</h1>
            <div class="mb-7"></div>
            <p class="description">
              We have started with a small size of local charities and will
              continue to grow as new charities join our network.
            </p>
          </div>
          <div class="div application-info flex flex-col items-center">
            <div class="image-container">
              <img src="../../../assets/temp-images/icons/dollars.png" />
            </div>
            <div class="mb-8"></div>
            <h1 class="font-cholla-slab-ot-no-weight">Manage Gifts</h1>
            <div class="mb-7"></div>
            <p class="description">
              We keep track of your contribution history and what your current
              wallet holds. Every month, we send your charity their amount from
              the charity wallet.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
