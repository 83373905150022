<div id="page-about" class="page">
  <!-- NOTE: Section 1 -->
  <div id="section-1" class="section image">
    <img
      class="image-for-mobile"
      src="../../../assets/temp-images/about-section-1-background-image-kids.png"
    />
    <div class="text-box">
      <p class="pre-header">What is DollarZing?</p>
      <h1 class="header thick">A Platform for Communities</h1>
      <p class="body">
        DollarZing, a digital game and platform tied to a community of gifters
        and receivers. From other peers like you, to charitable causes, everyone
        benefits from participating.
      </p>
    </div>
  </div>
  <!-- NOTE: Section 2 -->
  <div id="section-2" class="section">
    <div class="portion" id="portion-1">
      <img
        class="side-image"
        src="../../../assets/temp-images/about-section-2-for-us-by-us.png"
      />
      <div class="text-box">
        <p class="pre-header">More than a game</p>
        <h1 class="header thick">For us By Us</h1>
        <p class="body">
          DollarZing will bring together local and international charities into
          the peer-to-peer community. As a source of receiving from our peers,
          these charity organizations then gift to their local causes.
          <br /><br />
          As a member, you will be able to see the community impacts that your
          gifted dollars have created. Every time you play DollarZing, you
          automatically contribute to our charity member's causes. Everyone
          participates in gifting and recieving.
        </p>
      </div>
    </div>
    <div class="portion" id="portion-2">
      <img
        class="side-image"
        src="../../../assets/temp-images/about-section-2-peer-individuals.png"
      />

      <div class="text-box">
        <h1 class="header">Peer Individuals</h1>
        <p class="body">
          As a peer member, you can play the Dollar challenge for an opportunity
          to receive up to $1024 USD in one single day. You then take part of
          these proceeds to gift to a charity of your choosing. You receive, and
          you gift.
        </p>
      </div>
    </div>
    <div class="portion" id="portion-3">
      <img
        class="side-image"
        src="../../../assets/temp-images/about-section-2-charities.png"
      />

      <div class="text-box">
        <h1 class="header">Charities</h1>
        <p class="body">
          As a charity, you will be able to organize your marketing efforts on
          our portal with an opportunity to receive proceeds from our member
          community. Your portal is the starting point for our community to
          learn about your mission and service.
        </p>
      </div>
    </div>
    <div class="portion" id="portion-4">
      <img
        class="side-image"
        src="../../../assets/temp-images/about-section-2-communities.png"
      />

      <div class="text-box">
        <h1 class="header">Communities</h1>
        <p class="body">
          Sponsors get to interact directly with our members as gifters of their
          products and services. They receive direct interaction with our
          members and exposure of their products and services through our free
          game play.
        </p>
      </div>
    </div>
  </div>
  <!-- NOTE: Section 3 -->
  <div id="section-3" class="section image">
    <img
      class="image-for-mobile"
      src="../../../assets/temp-images/about-section-3-background-image-bags.png"
    />
    <div class="text-box bg-base-green">
      <p class="pre-header">The Ecosystem</p>
      <h1 class="header thick">DollarZing is about Exchanges</h1>
      <p class="body">
        The idea for DollarZing came from wanting to expose people to local
        impacts that go unnoticed. Helping your local community brings you
        positive social participation and well-being. The idea is that one
        individual exchanges with another, with his/her community, and receives
        an exchange as well.
      </p>
    </div>
  </div>
  <div class="whitespace"></div>
  <!-- NOTE: Section 4 -->
  <div id="section-4" class="section bg-base-green">
    <div id="portion-1" class="portion">
      <img
        class="side-image"
        src="../../../assets/temp-images/about-section-4-iphone-peer-to-peer.png"
      />
      <div class="text-box">
        <div class="container-header">
          <h1 class="header thick">From Peer to Peer</h1>
          <div class="line-under-header"></div>
        </div>
        <h1 class="subheader">
          Members play a game where value is exchanged amongst themselves.
        </h1>
        <p class="body">
          The App contains a game that uses a $1 bill from one member and
          another $1 bill from another member to then calculate who keeps both
          dollars from the challenge.
          <br />
          The receiver can then go ahead and join another game with another
          member to play $2 on $2 until the exchanges reach $1024, or gifts what
          they have received, or the member decides to keep their gifts.
        </p>
        <a routerLink="/game">
          <button class="button">
            <span class="text-long">Learn About The Game</span>
            <span class="text-short">Learn More</span>
          </button>
        </a>
      </div>
    </div>
    <div id="portion-2" class="portion">
      <img
        class="side-image"
        src="../../../assets/temp-images/about-section-4-iphone-peer-to-charities.png"
      />
      <div class="text-box">
        <div class="container-header">
          <h1 class="header thick">From Peers to Charities</h1>
          <div class="line-under-header"></div>
        </div>
        <h1 class="subheader">
          Members play a game which creates a fund for Charities.
        </h1>
        <p class="body no-pad">
          Local and international charities receive micro-donations from all of
          DollarZing’s active members. Every time a member receives from playing
          the game, a charity recieves a percentage starting at 10%. A member
          can choose their charity and research the charities impacts on the
          mobile app or the Charity Portal.
        </p>
      </div>
    </div>
    <div id="portion-3" class="portion">
      <img
        class="side-image"
        src="../../../assets/temp-images/about-section-4-browser-charities-to-peers.png"
      />
      <div class="text-box">
        <div class="container-header">
          <h1 class="header thick">From Charities Back to Peers</h1>
          <div class="line-under-header"></div>
        </div>
        <h1 class="subheader">
          Local charities work on impacts that benefit the community.
        </h1>
        <p class="body no-pad">
          Indirectly, local impacts means helping you by helping the local
          community to overcome necessary issues that are not funded by local
          governments or local patrons. These small charities organize impact
          projects that improve the overall living condition of the area or
          city. We will start small, but in time, growth can create positive
          change anywhere DollarZing is used.
        </p>
      </div>
    </div>
  </div>
  <!-- NOTE: Section 5 -->
  <div id="section-5" class="section">
    <div class="text-box">
      <h1 class="header thick">How it works:</h1>
      <h1 class="subheader">Press play to learn more about us</h1>
    </div>
    <video
      id="video-dollar-zing"
      src="../../../assets/videos/Dollar Zing-FinalCut (1).mp4"
      poster="../../../assets/videos/DZ_Website VIDEO Frame v1-01.png"
      controls=""
    >
      Your browser doesn't support HTML5 video tag.
    </video>
  </div>
</div>
