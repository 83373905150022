import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { ActiveCampaignService } from 'src/app/services/active-campaign.service';
import { scrollToTop } from 'src/assets/utils/scrollToTop';

@Component({
  selector: 'app-ambassador-enrollment-page',
  templateUrl: './ambassador-enrollment-page.component.html',
  styleUrls: ['./ambassador-enrollment-page.component.scss'],
})
export class AmbassadorEnrollmentPageComponent
  implements OnInit, AfterViewInit
{
  constructor(
    private renderer: Renderer2,
    private activeCampaignService: ActiveCampaignService
  ) {}

  ngOnInit(): void {
    this.activeCampaignService.loadJsScript(this.renderer, 'ambassador');
    scrollToTop();
  }

  ngAfterViewInit() {
    // @ts-ignore
    // hbspt.forms.create({
    //   portalId: '8774320',
    //   formId: '1837a308-f6c2-4395-b660-ae1269217c5e',
    //   target: '#ambassador-hubspot-form',
    // });
  }
}
