import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup-plaza-proposals',
  templateUrl: './popup-plaza-proposals.component.html',
  styleUrls: ['./popup-plaza-proposals.component.scss'],
})
export class PopupPlazaProposalsComponent implements OnInit {
  // @Input() openPopupBool: boolean = false;
  showPopupBool: boolean = true;
  proposals = {
    english: [
      {
        name: 'Bigger Vision',
        path: '../../assets/docs/Bigger vision.pdf',
      },
      {
        name: 'Parking Proposal',
        path: '../../assets/docs/Parking Proposal.pdf',
      },
      {
        name: 'Summary',
        path: '../../assets/docs/Summary.jpeg',
      },
      {
        name: 'Creating a New Space',
        path: 'https://www.youtube.com/watch?v=fvKCcGdDYHg',
      },
    ],

    spanish: [
      {
        name: 'Una Vision Mas Grande',
        path: '../../assets/docs/Una Vision Mas Grande.pdf',
      },
      {
        name: 'Propuesta de Estacinamiento',
        path: '../../assets/docs/Propuesta de Estacinamiento.pdf',
      },
      {
        name: 'Resumen',
        path: '../../assets/docs/Resumen.jpeg',
      },
      {
        name: 'Creación de un Nuevo Espacio',
        path: 'https://www.youtube.com/watch?v=Y8BWtddlQVM',
      },
    ],
  };

  constructor() {}

  ngOnInit(): void {}

  showPopup(): void {
    this.showPopupBool = true;
    document.getElementById('body')?.classList.add('no-scroll');
  }

  closePopup(): void {
    this.showPopupBool = false;
  }

  openDocument(document: string) {
    window.open(document);
  }
}
