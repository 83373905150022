import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'DZ-Angular-Website';
  route = '';
  transparentNavbar = false;

  constructor(private router: Router) {
    // router.events.subscribe((route) => {
    //   if (route: )
    // });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route = event.url;
        this.checkForNavbarTransparency();
      }
    });
  }

  checkForNavbarTransparency() {
    if (this.route == '/plaza-la-libertad') this.transparentNavbar = true;
    else this.transparentNavbar = false;
  }
}
