import { Component, OnInit, ViewChild } from '@angular/core';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { PopupPlazaProposalsComponent } from 'src/app/components/popup-plaza-proposals/popup-plaza-proposals.component';

@Component({
  selector: 'app-plaza-libertad-page',
  templateUrl: './plaza-libertad-page.component.html',
  styleUrls: ['./plaza-libertad-page.component.scss'],
})
export class PlazaLibertadPageComponent implements OnInit {
  faExternalLinkAlt = faExternalLinkAlt;
  scrollAtTop: boolean = true;
  // child: PopupPlazaProposalsComponent;
  // proposalsAreOpened:boolean = false;

  constructor() {}

  ngOnInit(): void {
    const droneLoopVideo = document.getElementById('drone-loop-video');
    // @ts-ignore
    droneLoopVideo.muted = true;
    // @ts-ignore
    droneLoopVideo.play();
    setTimeout(
      () => document.getElementById('video-header')?.classList.add('visible'),
      1000
    );

    // $('#slider').on('input change', (e) => {
    //   const sliderPos = e.target.value;
    //   // Update the width of the foreground image
    //   $('.foreground-img').css('width', `${sliderPos}%`);
    // });

    // document.getElementById('slider')?.addEventListener('input', (e) => {
    //   // @ts-ignore
    //   const sliderPos = e.target.value;
    //   // @ts-ignore
    //   document.getElementById('after-image').style.width = `${sliderPos}%`;
    // });

    let slidingOut = false;

    let slideTease = setInterval(() => {
      if (slidingOut == false) {
        // @ts-ignore
        document.getElementById('after-image-1').style.width = `25%`;
        slidingOut = true;
      } else {
        // @ts-ignore
        document.getElementById('after-image-1').style.width = `0%`;
        slidingOut = false;
      }
    }, 3000);

    let fadingIn = false;

    let slideFading = setInterval(() => {
      let afterImage = document.getElementById('after-image-fade');
      if (fadingIn == false) {
        // @ts-ignore
        afterImage?.style.opacity = 1;
        fadingIn = true;
      } else {
        // @ts-ignore
        afterImage?.style.opacity = 0;
        fadingIn = false;
      }
    }, 2500);

    let slideAnimationStarted = false;

    document.addEventListener('scroll', (e) => {
      let stats = document.getElementById('section-3')?.getBoundingClientRect();
      // console.log(stats);
      // @ts-ignore
      // let distanceFromBottom = window.innerHeight - stats.y;
      // console.log('distanceFromBottom: ' + distanceFromBottom + 'px');

      // @ts-ignore
      if (stats.y < 200 && slideAnimationStarted == false) {
        clearInterval(slideTease);
        slideAnimationStarted = true;
        // @ts-ignore
        document.getElementById('after-image-1').style.width = `100%`;
      }

      if (document.documentElement.scrollTop !== 0) {
        this.scrollAtTop = false;
      } else {
        this.scrollAtTop = true;
        // console.log('at top');
      }
    });

    // @ts-ignore
    // setTimeout(
    //   // @ts-ignore
    //   () => (document.getElementById('after-image-1').style.width = `100%`),
    //   1000
    // );
  }

  //   openProposalsPopup():void {
  // proposalsAreOpened
  //   }

  openInstagram(): void {
    window.open('https://www.instagram.com/plazalalibertad/');
  }
}
