import { Component, OnInit } from '@angular/core';
import { scrollToTop } from 'src/assets/utils/scrollToTop';

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss'],
})
export class AboutPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    scrollToTop();
  }
}
