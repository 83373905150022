<div
  id="page-privacy"
  class="mx-auto my-20 w-10/12 lg:mx-auto lg:my-24 lg:w-10/12"
>
  <div
    class="lg:py-2 mt-20"
    data-pg-class-style="column"
    data-pg-name="Titles"
    data-pg-class-style-inline=" mt-20"
  >
    <h1
      data-pg-class-style="h1_title"
      class="
        mb-4
        mt-2
        lg:mt-auto lg:mb-2
        text-3xl
        leading-snug
        text-green-600
        lg:text-4xl
      "
      style="font-family: 'Lato', sans-serif; font-weight: 900"
    >
      Privacy Policy
    </h1>
    <h5
      style="font-family: 'Bitter', serif; font-weight: 700"
      data-pg-class-style="h5_title"
      class="
        lg:text-left lg:text-2xl
        text-xl text-green-500
        mx-auto
        text-left
        leading-snug
        lg:mb-6
      "
    >
      DollarZing.com
    </h5>
  </div>
  <div
    data-pg-class-style="column"
    class="lg:py-2 mt-20 lg:mt-12"
    style="font-family: 'Lato', sans-serif"
    data-pg-name="Intro"
    data-pg-class-style-inline=" mt-20 lg:mt-12"
  >
    <p
      class="leading-8 text-lg mb-4"
      data-pg-class-style="paragraph_terms"
      data-pg-class-style-inline=""
    >
      DollarZing cares about your privacy. For this reason, we collect and use
      personal information only as needed to deliver our products, services,
      websites and mobile applications, and to communicate with you about the
      same, or as you have requested (collectively, our &ldquo;Services&rdquo;).
      Your personal information includes information such as:
    </p>
    <ul style="font-family: 'Lato', sans-serif">
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem">Name</span>
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem">Address</span>
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem">Telephone number&nbsp;</span>
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        Date of birth&nbsp;
        <br />
      </li>
      <li
        data-pg-class-style="paragraph_points"
        class="leading-7 mt-4 text-lg list-inside list-disc"
      >
        <span style="font-size: 1.125rem">Email address&nbsp;</span>
      </li>
      <li
        data-pg-class-style="paragraph_points"
        class="leading-7 mt-4 text-lg list-inside list-disc"
      >
        <span style="font-size: 1.125rem">Billing and payment information</span>
        <br />
      </li>
      <li
        data-pg-class-style="paragraph_points"
        class="leading-7 mt-4 text-lg list-inside list-disc"
      >
        <span style="font-size: 1.125rem"
          >Candidate information (for job applicants)&nbsp;</span
        >
      </li>
      <li
        data-pg-class-style="paragraph_points"
        class="leading-7 mt-4 text-lg list-inside list-disc"
      >
        <span style="font-size: 1.125rem"
          >Other data collected that could directly or indirectly identify
          you.&nbsp;</span
        >
      </li>
    </ul>
    <p
      class="leading-8 text-lg mb-4 mt-6"
      data-pg-class-style="paragraph_terms"
      data-pg-class-style-inline=" mt-6"
    >
      Our Privacy Policy not only explains how and why we use your personal
      information that we collect, but also how you can access, update or
      otherwise take control of your personal information. We&rsquo;ve also
      created a Transparency Center at www.admin@DollarZing.com that offers
      answers to your most common questions, quick links to access your Account
      Settings, instructions on how to exercise certain rights that might be
      available to you, and definitions to key terms and concepts noted in this
      Privacy Policy.
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      If at any time you have questions about our practices or any of your
      rights described below, you may reach our dedicated team that supports
      this office by contacting us at admin@dollarzing.com. This inbox is
      actively monitored and managed so that we can deliver an experience that
      you can confidently trust.
    </p>
  </div>
  <div
    data-pg-name="What info we collect"
    data-pg-class-style="column"
    class="lg:py-2 lg:mt-20 mt-20"
    style="font-family: 'Lato', sans-serif"
    data-pg-class-style-inline=" lg:mt-20 mt-20"
  >
    <h5
      data-pg-class-style="h5_title"
      class="
        lg:text-left lg:text-2xl
        text-xl text-green-500
        mx-auto
        text-left
        leading-snug
        lg:mb-6
      "
      style="font-weight: bold; text-decoration: underline"
    >
      What information we collect, how we collect it, and why
    </h5>
    <p
      data-pg-class-style="paragraph_terms"
      class="leading-8 text-lg mb-4 mt-4"
      data-pg-class-style-inline=" mt-4"
    >
      Much of what you likely consider personal information is collected
      directly from you when you:
    </p>
    <ul style="font-family: 'Lato', sans-serif" class="mb-8">
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem"
          >create an account or purchase any of our Services (ex: billing
          information, including name, address, credit card number, government
          identification);
        </span>
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem"
          >request assistance from our customer support team (ex: phone number);
        </span>
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem"
          >complete contact forms or request newsletters or other information
          from us (ex: email); or Date of birth&nbsp;&nbsp;</span
        >
        <br />
      </li>
      <li
        data-pg-class-style="paragraph_points"
        class="leading-7 mt-4 text-lg list-inside list-disc"
      >
        <span style="font-size: 1.125rem"
          >participate in our $ZingCash challenge and surveys, apply for a job,
          or otherwise participate in activities we promote that might require
          information about you.</span
        >
      </li>
    </ul>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      However, we also collect additional information when delivering our
      Services to you to ensure necessary and optimal performance. These methods
      of collection may not be as obvious to you, so we thought we&rsquo;d
      highlight and explain a bit more about what these might be (as they vary
      from time to time):
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      Cookies and similar technologies on our websites and our mobile
      applications allow us to track your browsing behavior, links clicked,
      items purchased, your device type, and to collect various data, including
      analytics, about how you use and interact with our Services. These
      technologies automatically collect data when you use and interact with our
      Services, including metadata, log files, cookie/device IDs, page load
      time, server response time, and approximate location information to
      measure website performance and improve our systems, including optimizing
      DNS resolution, network routing and server configurations. Specifically,
      interactions with the features, content and links (including those of
      third-parties, such as social media plugins) contained within the
      Services, Internet Protocol (IP) address, browser type and settings, the
      date and time the Services were used, information about browser
      configuration and plugins, language preferences and cookie data,
      information about devices accessing the Services, including type of
      device, what operating system is used, device settings, application IDs,
      unique device identifiers and error data is collected. All this allows us
      to provide you with more relevant product offerings, a better experience
      on our sites and mobile applications, and to collect, analyze and improve
      the performance of our Services. We may also collect your location (IP
      address) so that we can personalize our Services. For additional
      information, and to learn how to manage the technologies we utilize.
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      Supplemented Data may be received about you from other sources, including
      publicly available databases or third parties from whom we have purchased
      data, in which case we may combine this data with information we already
      have about you so that we can update, expand and analyze the accuracy of
      our records, assess the qualifications of a candidate for employment,
      identify new members, and provide products and services that may be of
      interest to you. If you provide us personal information about others, or
      if others give us your information, we will only use that information for
      the specific reason for which it was provided to us.
    </p>
  </div>
  <div
    data-pg-class-style="column"
    class="lg:py-2 lg:mt-20 mt-20"
    style="font-family: 'Lato', sans-serif"
    data-pg-class-style-inline=" lg:mt-20 mt-20"
    data-pg-name="How we Utilize Info"
  >
    <h5
      data-pg-class-style="h5_title"
      class="
        lg:text-left lg:text-2xl
        text-xl text-green-500
        mx-auto
        text-left
        leading-snug
        lg:mb-6
      "
      style="font-weight: bold; text-decoration: underline"
    >
      How we utilize information.
    </h5>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      We strongly believe in both minimizing the data we collect and limiting
      its use and purpose to only that (1) for which we have been given
      permission, (2) as necessary to deliver the Services you purchase or
      interact with, or (3) as we might be required or permitted for legal
      compliance or other lawful purposes:<br />
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      Delivering, improving, updating and enhancing our Services. We collect
      various information relating to your game play and purchase of $zingcash
      along with your gifting to your charity wallet and charities, use and/or
      interactions with our Services. We utilize this information to:
    </p>
    <ul style="font-family: 'Lato', sans-serif" class="mb-12">
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem"
          >Improve and optimize the operation and performance of our Services
          (again, including our websites and mobile applications)</span
        >
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem"
          >Diagnose problems with and identify any security risks, errors, or
          needed enhancements to the Services</span
        >
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem"
          >Detect and prevent fraud and abuse of our Services and
          systems&nbsp;</span
        >
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem"
          >Collecting aggregate statistics about use of the Services&nbsp;</span
        >
        <br />
      </li>
      <li
        data-pg-class-style="paragraph_points"
        class="leading-7 mt-4 text-lg list-inside list-disc"
      >
        <span style="font-size: 1.125rem"
          >Understand and analyze how you use our Services and what products and
          services are most relevant to you.</span
        >
      </li>
    </ul>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      Much of the data collected is aggregated or statistical data about how
      individuals use our Services, and is not linked to any personal
      information.
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      Sharing with trusted third parties. We may share your personal information
      with affiliated companies within our corporate family, with third parties
      with which we have partnered to allow you to integrate their services into
      our own Services, and with trusted third party service providers as
      necessary for them to perform services on our behalf, such as:
    </p>
    <ul style="font-family: 'Lato', sans-serif" class="mb-12">
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem">Processing credit card payments</span>
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem">Conducting contests or surveys</span>
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem"
          >Performing analysis of our Services and customers demographics</span
        >
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem"
          >Communicating with you, such as by way email or survey delivery</span
        >
        <br />
      </li>
      <li
        data-pg-class-style="paragraph_points"
        class="leading-7 mt-4 text-lg list-inside list-disc"
      >
        <span style="font-size: 1.125rem"
          >Customer relationship management</span
        >
      </li>
      <li
        data-pg-class-style="paragraph_points"
        class="leading-7 mt-4 text-lg list-inside list-disc"
      >
        <span style="font-size: 1.125rem"
          >Recruiting support and related services. These third parties (and any
          subcontractors they may be permitted to use) have agreed not to share,
          use or retain your personal information for any purpose other than as
          necessary for the provision of Services.<br
        /></span>
      </li>
    </ul>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      We will also disclose your information to third parties:
    </p>
    <ul style="font-family: 'Lato', sans-serif" class="mb-12">
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem"
          >in the event that we sell or buy any business or assets (whether a
          result of liquidation, bankruptcy or otherwise), in which case we will
          disclose your data to the prospective seller or buyer of such business
          or assets; or&nbsp;</span
        >
        <br />
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem"
          >if we sell, buy, merge, are acquired by, or partner with other
          companies or businesses, or sell some or all of our assets. In such
          transactions, your information may be among the transferred
          assets.</span
        >
      </li>
    </ul>
    <ul style="font-family: 'Lato', sans-serif" class="mb-12">
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem">Email </span>
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem">Text (SMS) messages </span>
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem">Telephone calls</span>
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem"
          >Messenger applications (e.g. WhatsApp, etc.)&nbsp;</span
        >
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem"
          >Automated phone calls or text messages.</span
        >
      </li>
    </ul>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      You may also update your membership preferences with respect to receiving
      communications from us and/or our partners by signing into your account
      and visiting &ldquo;Account Settings&rdquo; page.
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      If we collect information from you in connection with a co-branded offer,
      it will be clear at the point of collection who is collecting the
      information and whose privacy policy applies. In addition, it will
      describe any choice options you have in regards to the use and/or sharing
      of your personal information with a co-branded partner, as well as how to
      exercise those options. We are not responsible for the privacy practices
      or the content of third-party sites. Please read the privacy policy of any
      website you visit.
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      If you make use of a service that allows you to import contacts (ex. using
      email marketing services to send emails on your behalf), we will only use
      the contacts and any other personal information for the requested service.
      If you believe that anyone has provided us with your personal information
      and you would like to request that it be removed from our database, please
      contact us at admin@dollarzing.com.
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      Transfer of personal information abroad. If you utilize our Services from
      a country other than the country where our servers are located, your
      personal information may be transferred across international borders,
      which will only be done when necessary for the performance of our contract
      with you, when we have your consent to do so, or when the appropriate
      standard contractual clauses are in place. Also, when you call us or
      initiate a chat, we may provide you with support from one of our global
      locations outside your country of origin.
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      Compliance with legal, regulatory and law enforcement requests. We
      cooperate with government and law enforcement officials and private
      parties to enforce and comply with the law. We will disclose any
      information about you to government or law enforcement officials or
      private parties as we, in our sole discretion, believe necessary or
      appropriate to respond to claims and legal process (such as subpoena
      requests), to protect our property and rights or the property and rights
      of a third party, to protect the safety of the public or any person, or to
      prevent or stop activity we consider to be illegal or unethical.
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      To the extent we are legally permitted to do so, we will take reasonable
      steps to notify you in the event that we are required to provide your
      personal information to third parties as part of legal process.
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      How we secure, store and retain your data. We follow generally accepted
      standards to store and protect the personal information we collect, both
      during transmission and once received and stored, including utilization of
      encryption where appropriate.
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      We retain personal information only for as long as necessary to provide
      the Services you have requested and thereafter for a variety of legitimate
      legal or business purposes. These might include retention periods:
    </p>
    <ul style="font-family: 'Lato', sans-serif" class="mb-12">
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem"
          >mandated by law, contract or similar obligations applicable to our
          business operations;
        </span>
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem"
          >for preserving, resolving, defending or enforcing our
          legal/contractual rights; or&nbsp;</span
        >
        <br />
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem"
          >needed to maintain adequate and accurate business and financial
          records.&nbsp;</span
        >
        <br />
      </li>
    </ul>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      If you have any questions about the security or retention of your personal
      information, you can contact us at admin@dollarzing.com
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      How you can access, update or delete your data. To easily access, view,
      update, delete or port your personal information, or to update your
      subscription preferences, please sign into your Account and visit
      &ldquo;Account Settings.&rdquo; Please visit our Transparency Center for
      additional information and guidance for accessing, updating or deleting
      data.&nbsp;<br />
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      If you make a request to delete your personal information and that data is
      necessary for the products or services you have purchased, the request
      will be honored only to the extent it is no longer necessary for any
      Services purchased or required for our legitimate business purposes or
      legal or contractual record keeping requirements.&nbsp;<br />
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      If you are unable for any reason to access your Account Settings or our
      Transparency Center, you may also contact us by one of the methods
      described in the &ldquo;Contact Us&rdquo; section below.&nbsp;<br />
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      Age restrictions. Our Services are available for purchase only for those
      age 18 or older. Our Services are not targeted to, intended to be consumed
      by or designed to entice individuals under the age of 18. If you know of
      or have reason to believe anyone under the age of 18 has provided us with
      any personal information, please contact us.<br />
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      Non-Discrimination. We will not discriminate against you for exercising
      any of your privacy rights. Unless permitted under applicable laws, we
      will not: <br />
    </p>
    <ul style="font-family: 'Lato', sans-serif" class="mb-12">
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem"
          >Deny you goods or services.&nbsp;</span
        >
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        <span style="font-size: 1.125rem"
          >Charge you different prices or rates for goods or services, including
          through granting discounts or other benefits, or imposing
          penalties.&nbsp;</span
        >
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        Provide you a different level or quality of goods or services.&nbsp;
        <br />
      </li>
      <li
        class="leading-7 mt-4 text-lg list-inside list-disc"
        data-pg-class-style="paragraph_points"
      >
        Suggest that you may receive a different price or rate for goods or
        services or a different level or quality of goods or services.
        <br />
      </li>
    </ul>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      Changes to this policy. We reserve the right to modify this Privacy Policy
      at any time. If we decide to change our Privacy Policy, we will post those
      changes to this Privacy Policy and any otherplaces we deem appropriate, so
      that you are aware of what information we collect, how we use it, and
      under what circumstances, if any, we disclose it. If we make material
      changes to this Privacy Policy, we will notify you here, by email, or by
      means of a notice on our home page, at least thirty (30) days prior to the
      implementation of the changes.
    </p>
    <p data-pg-class-style="paragraph_terms" class="leading-8 text-lg mb-4">
      Contact us. If you have any privacy-related questions, concerns or
      complaints about our Privacy Policy, our practices or our Services, you
      may contact our Administration Office by email at
      admin@dollarzing.com.&nbsp;
    </p>
    <p
      data-pg-class-style="paragraph_highllights"
      class="font-bold mb-2 mt-6 text-lg"
    >
      We will respond to all requests, inquiries or concerns within thirty (30)
      days.<br />
    </p>
  </div>
</div>
