import { Component, OnInit } from '@angular/core';

import Splide, { SplideOptions } from '@splidejs/splide';
import { scrollToTop } from 'src/assets/utils/scrollToTop';
// import { Splide } from '@splidejs/splide';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  splideOptions: SplideOptions = {
    type: 'loop',
    perPage: 1,
    arrows: false,
    autoplay: true,
    interval: 2500,
    pauseOnHover: true,
  };

  constructor() {}

  ngOnInit(): void {
    scrollToTop();
    this.initializeCarousel();
  }

  initializeCarousel() {
    new Splide('#carousel', this.splideOptions).mount();
  }
}
