<section
  style="background-color: #9dde4e"
  class="block"
  data-pg-class-style="container"
  data-pg-name="Hero Charity Enrollment"
>
  <div
    class="flex flex-wrap justify-center items-center"
    data-pg-class-style="row"
    data-pg-class-style-inline=" items-center justify-center"
    id="page-ambassador-enrollment-top-section"
  >
    <div
      data-pg-class-style="column"
      class="my-10 mx-auto md:w-4/12 md:ml-24 lg:w-4/12 w-9/12 sm:w-7/12 lg:mt-6 lg:mb-10 lg:ml-32 lg:mr-8"
      data-pg-class-style-inline=" my-10 mx-auto md:w-4/12 md:ml-24 lg:w-4/12 w-9/12 sm:w-7/12 lg:mt-6 lg:mb-10 lg:ml-32 lg:mr-8"
    >
      <img src="../../../assets/images/Home2 Member@3x-8.png" class="lg:px-6" />
    </div>
    <div
      class="lg:w-4/12 block order-last md:w-4/12 md:mr-20 my-auto w-9/12 sm:w-8/12 lg:mr-auto xl:w-5/12 xl:mr-24"
      data-pg-class-style="column"
      data-pg-class-style-inline=" lg:w-4/12 block order-last md:w-4/12 md:mr-20 my-auto w-9/12 sm:w-8/12 lg:mr-auto xl:w-5/12 xl:mr-24"
    >
      <h1
        data-pg-class-style="h2_title"
        class="lg:text-4xl lg:leading-tight leading-snug text-green-900 text-3xl mb-6 lg:w-full sm:w-9/12 w-full mr-auto"
        style="font-family: 'Bitter', serif; font-weight: 700"
        data-pg-class-style-inline=" lg:w-full sm:w-9/12 w-full mr-auto"
      >
        Become a Zinger
      </h1>
      <p
        data-pg-class-style="p_text"
        class="md:text-base text-base lg:text-base text-green-800 lg:mb-4 lg:w-full sm:w-9/12 w-full mr-auto"
        style="font-family: 'Lato', sans-serif; font-weight: 700"
        data-pg-class-style-inline=" text-base lg:text-base lg:w-full sm:w-9/12 w-full mr-auto"
      >
        Subscribe for our Newsletter
      </p>
    </div>
  </div>
</section>
<section>
  <div data-pg-class-style="container" class="block">
    <div
      class="flex flex-wrap justify-center mt-20 lg:mt-24 lg:mb-16"
      data-pg-class-style="row"
      data-pg-class-style-inline=" mt-20 lg:mt-24 lg:mb-16"
    >
      <div
        class="lg:w-4/12 w-9/12 sm:w-8/12 lg:mx-12 xl:ml-24"
        data-pg-class-style="column"
        data-pg-class-style-inline=" lg:w-4/12 w-9/12 sm:w-8/12 lg:mx-12 xl:ml-24"
        style="font-family: 'Lato', sans-serif; font-weight: 400"
      >
        <h2
          style="font-family: 'Lato', sans-serif; font-weight: 900"
          data-pg-class-style="h5_title"
          class="lg:text-left lg:text-2xl text-xl text-green-500 text-left leading-snug mx-auto lg:mb-12 mb-12"
          data-pg-class-style-inline=" lg:mb-12 mb-12"
        >
          Our Newsletter
        </h2>
        <p
          data-pg-class-style="p_text"
          class="md:text-base text-base lg:text-base text-green-800 lg:mb-6 mb-8"
          data-pg-class-style-inline=" lg:text-base lg:mb-6 mb-8"
        >
          Thank you for considering joining our Newsletter. We are looking for members willing to help us test our platform and other services as we embark on our journey to launch DollarZing and its forecasted impacts.

        </p>
        <p
          data-pg-class-style="p_text"
          class="md:text-base text-base lg:text-base text-green-800 lg:mb-6 mb-8"
          data-pg-class-style-inline=" lg:text-base lg:mb-6 mb-8"
        >
          In the coming weeks we will be notifying you of upcoming releases, product launches, user testing opportunities, and marketing events near you as we continue to build and refine this exciting new platform. 
        </p>
        <p class="mb-8 lg:text-base">
          Thank you for considering to join us, we&apos;ll be in touch!
        </p>
      </div>
      <div
        class="w-9/12 lg:w-5/12 lg:text-xs sm:w-8/12 mt-10 mb-16 sm:mb-20 lg:my-0 lg:ml-4 lg:mr-12 xl:mr-24"
        data-pg-class-style="column"
        data-pg-class-style-inline=" w-9/12 lg:w-5/12 lg:text-xs sm:w-8/12 mt-10 mb-16 sm:mb-20 lg:my-0 lg:ml-4 lg:mr-12 xl:mr-24"
        style="font-family: 'Lato', sans-serif"
      >
        <div id="ambassador-hubspot-form">
          <!-- <script charset="utf-8" type="text/javascript" src="https://js.hsforms.net/forms/shell.js"></script>
            <script>
              hbspt.forms.create({
                portalId: "8774320",
                formId: "1837a308-f6c2-4395-b660-ae1269217c5e"
              });
            </script> -->
          <div class="_form_7"></div>
        </div>
      </div>
    </div>
  </div>
</section>
