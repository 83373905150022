<div id="page-plaza-libertad" class="page">
  <!-- NOTE: Section 1 -->
  <div id="section-1" class="section image">
    <video
      autoplay
      loop
      muted
      muted="muted"
      webkit-playsinline="true"
      playsinline="true"
      id="drone-loop-video"
    >
      <!-- poster="../../../assets/images/plaza-la-libertad/plaza-shot-1.JPG" -->
      <source src="../../../assets/videos/plaza-la-libertad-drone.mp4" />
    </video>
    <div class="dark-overlay"></div>
    <img
      class="image-for-mobile"
      src="../../../assets/images/plaza-la-libertad/plaza-shot-1.JPG"
    />
    <div class="text-box" id="video-header">
      <p class="pre-header">A landmark of culture</p>
      <h1 class="header thick">Plaza La Libertad</h1>
      <p class="body">
        A landmark for sovereignty, liberty, and resilience. Rooted deep within
        Puerto Rican culture, Plaza La Libertad will be brought back into the
        community to show off the beauty and characteristics of its heritage.
      </p>
    </div>
  </div>
  <!-- NOTE Section Video -->
  <div id="section-video" class="section">
    <div class="text-box">
      <h1 class="header thick">Our Gameplan:</h1>
      <h1 class="video-subheader" id="plaza-video-subheader">
        Press play to learn more
      </h1>
    </div>
    <video
      id="video-plaza"
      src="../../../assets/videos/plaza-promo.mov"
      poster="../../../assets/images/plaza-la-libertad/videothumbnailcropped.jpg"
      controls=""
    >
      Your browser doesn't support HTML5 video tag.
    </video>
  </div>
  <!-- NOTE: Section Donate-2 -->
  <div id="section-donate-2" class="section">
    <div class="text-box">
      <!-- <p class="pre-header">Safety and Cleaning</p> -->
      <h1 class="header thick main">Be the change</h1>
      <p class="body">
        This work isn't possible without supporters like you. Consider signing
        our petition and moving our cause forward.
      </p>
    </div>
    <div class="button-container">
      <a target="#" href="https://chng.it/WZpZz2RH">
        <button class="button">Sign Petition</button>
      </a>
    </div>
  </div>
  <!-- NOTE: Section 2 -->
  <div id="section-2" class="section">
    <!-- <video
      id="video-plaza-la-libertad"
      src="../../../assets/videos/Dollar Zing-FinalCut (1).mp4"
      poster="../../../assets/videos/DZ_Website VIDEO Frame v1-01.png"
      controls=""
    >
      Your browser doesn't support HTML5 video tag.
    </video> -->
    <h1>See what is possible</h1>
    <h2>Continue scrolling to explore our vision.</h2>
  </div>
  <!-- NOTE: Section 3 -->
  <div id="section-3" class="section image">
    <div class="draggable-image-container">
      <div class="img before-img" id="before-image-1"></div>
      <div class="img after-img" id="after-image-1"></div>
      <!-- <div class="img before-img" id="before-image-2"></div>
      <div class="img after-img" id="after-image-2"></div> -->
    </div>
    <div class="fading-image-container">
      <div class="img before-img"></div>
      <div class="img after-img" id="after-image-fade"></div>
    </div>
    <!-- <div class="dark-overlay">
      <input
        type="range"
        min="1"
        max="100"
        value="50"
        class="slider"
        name="slider"
        id="slider"
      />
    </div> -->
    <div class="text-box">
      <p class="pre-header">the future we see</p>
      <h1 class="header thick">Our Vision</h1>
      <p class="body">
        Plaza la Libertad will be an essential location to display Puerto Rican
        culture to the Americas. Where people around the world will meet and
        gather for community activities. A place for farmers and artist of all
        types can market their goods. All of this will be possible by our
        supporting community and partner organizations.
      </p>
    </div>
  </div>
  <!-- NOTE: Section Donate-1 -->
  <div id="section-donate-1" class="section">
    <div class="text-box">
      <!-- <p class="pre-header">Safety and Cleaning</p> -->
      <h1 class="header thick main">Fuel Our Cause</h1>
      <p class="body">
        No vehicle can move without a source of energy. Your financial
        contribution will be the fuel to the future of Plaza La Libertad.
        Consider enabling us in moving this dream forward.
      </p>
    </div>
    <div class="button-container">
      <a routerLink="/sign-to-donate">
        <button class="button">Pledge to Donate</button>
      </a>
    </div>
  </div>
  <!-- NOTE: Section 4 -->
  <div id="section-4" class="section">
    <div class="portion" id="portion-1">
      <img
        class="side-image"
        src="../../../assets/images/plaza-la-libertad/cleaning/pressure-washing.jpeg"
      />

      <div class="text-box">
        <p class="pre-header">Safety and Cleaning</p>
        <h1 class="header thick main">Phase One</h1>
        <h2 class="header step one">Step One</h2>
        <p class="body step one">
          The 1st goal in cleaning the plaza is removing the hazardous and
          rusted metal in and around the structure. This step will allow us to
          do a full clean of the plaza in preparation for paint.
        </p>
        <h2 class="header step two">Step Two</h2>
        <p class="body step two">
          Plaza la Libertad is long overdue for a good cleaning. With the help
          of supplies from the DRNA and Parques Nacionales, we will be cleaning
          and scrubbing the plaza inside and out. With the use of a high-powered
          pressure washer, we are sure to remove all dust, dirt, and debris.
          This will instantly brighten the grounds and bring a noticeable nicer
          aesthetic to the area.
        </p>
      </div>
    </div>
    <div class="portion" id="portion-2">
      <img
        class="side-image"
        src="../../../assets/images/plaza-la-libertad/painting/people-painting.png"
      />

      <div class="text-box">
        <p class="pre-header">Painting and partnership</p>
        <h1 class="header thick">Phase Two</h1>
        <p class="body">
          With the plaza prepped and ready to go, the next step is to give Plaza
          la Libertad a refreshed look. We are excited to be working with
          Cromapolis, a respected organization that has a portfolio of many
          community murals across Puerto Rico. We will be hosting volunteer
          days, welcoming all to come join Cromapolis, Global Fokus, &
          DollarZing in painting the Plaza. Follow our Insatgram @ DollarZing to
          stay up to date!
        </p>
      </div>
    </div>
  </div>
  <!-- NOTE: Section 5 -->
  <div id="section-5" class="section bg-base-green">
    <div class="col-text">
      <div class="text-box">
        <p class="pre-header">the gift that keeps on giving</p>
        <h1 class="header thick">Creating Opportunities</h1>
        <p class="body">
          Through this work, we can revive a new space for the community to come
          together and thrive together, ultimately creating more opportunities
          for others to create opportunities. It will be a focal point for all
          people in Condado.
        </p>
      </div>
      <div class="text-box-mini">
        <h2 class="header thick mini">Farmers Markets</h2>
        <p class="body">
          A plaza for local farmers to sell their fresh produce, promoting local
          growers and bringing community together
        </p>
      </div>
      <div class="text-box-mini">
        <h2 class="header thick mini">Social Hangout</h2>
        <p class="body">
          Free for the people to use publicly and commune together
        </p>
      </div>
      <div class="text-box-mini">
        <h2 class="header thick mini">Flea Markets</h2>
        <p class="body">
          This helps bring unique items from local artist, allowing them to
          display their makings and support the rich art culture by Puerto Rico.
        </p>
      </div>
      <div class="text-box-mini">
        <h2 class="header thick mini">Music Events</h2>
        <p class="body">
          With the shape of Plaza la Libertad, it holds a great acoustic sound
          for scheduled music events.
        </p>
      </div>
      <div class="text-box-mini">
        <h2 class="header thick mini">Local Artists</h2>
        <p class="body">
          Will bring colorful images that are pleasing to the eye, while giving
          artists the opportunity to create art that represents Plaza la
          Libertad.
        </p>
      </div>
    </div>
    <div class="col-images">
      <img
        class="side-image"
        src="../../../assets/images/plaza-la-libertad/opportunities/creating-opportunities-mesh-vert.png"
      />
    </div>
  </div>
  <!-- NOTE: Section 6 -->
  <div id="section-6" class="section">
    <div class="partners">
      <h1 class="title">Our Partners</h1>
      <div class="partners">
        <div class="partner">
          <div class="image dzing"></div>
          <h2 class="name">Dollarzing</h2>
        </div>
        <div class="partner">
          <div class="image integro"></div>
          <h2 class="name">Integro</h2>
        </div>
        <div class="partner">
          <div class="image croma"></div>
          <h2 class="name">Cromapolis</h2>
        </div>
        <div class="partner">
          <div class="image drna"></div>
          <h2 class="name">DRNA</h2>
        </div>
        <div class="partner">
          <div class="image parques"></div>
          <h2 class="name">Parques Nacionales</h2>
        </div>
        <div class="partner">
          <div class="image gfokus"></div>
          <h2 class="name">Global Fokus</h2>
        </div>
      </div>
    </div>
    <div class="enrollment">
      <div class="text-box">
        <h2 class="title">And people just like you.</h2>
        <p class="body">
          Your donations are really what make impacts like this possible.
          Consider partnering up with us through financial aid.
        </p>
        <div class="right-align">
          <a routerLink="/sign-to-donate">
            <button class="button">Pledge to Donate</button>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- NOTE: Section 7 -->
  <div id="section-7" class="section">
    <div class="dark-overlay"></div>
    <div class="text-box">
      <h1 class="header thick">...And so much more.</h1>

      <app-popup-plaza-proposals></app-popup-plaza-proposals>
      <!-- <a target="#" href="../../../assets/docs/plaza-la-libertad-proposal.pdf">
        <button class="button">
          View Proposals <i class="fas fa-external-link-alt"></i>
        </button>
      </a> -->
    </div>
  </div>
  <!-- TODO come back the fuck here -->
  <!-- NOTE: Call to Instagram -->
  <div
    id="call-to-instagram"
    class="section"
    (click)="openInstagram()"
    [class.hide]="scrollAtTop"
  >
    <!-- Instagram Icon -->

    <!-- <a target="#" href="https://www.instagram.com/plazalalibertad/"> -->
    <i class="fa-2x fa-instagram fab"></i>
    <!-- </a> -->
    Get our updates on Instagram!
  </div>
</div>
